import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useEffect, useState } from "react";
import { updateInvoice } from "../../../services/invoiceService";

interface Props {
    open: boolean;
    onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
    onSubmit: () => void;
    selectedInvoice: Invoice | null;
    modalType: "expected" | "charge" | "pay";
    oldDate: Date | null;
}

const DateModal: React.FC<Props> = ({
    open,
    onClose,
    onSubmit,
    selectedInvoice,
    modalType,
    oldDate,
}) => {
    const [date, setDate] = useState<Date | null>(null);
    useEffect(() => {
        setDate(oldDate);
    }, [oldDate]);
    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle>
                {modalType === "expected" ? "Fecha Esperada" : modalType === "charge" ? "Fecha de Cobro" : "Fecha de Pago"}
            </DialogTitle>
            <DialogContent>
                <Box>
                    <p>Seleccione la fecha de {modalType === "expected" ? "espera" : modalType === "charge" ? "cobro" : "pago"} para la factura seleccionada</p>
                </Box>
                <LocalizationProvider dateAdapter={AdapterDateFns
                }>
                    <DesktopDatePicker
                        label="Fecha"
                        format='dd/MM/yyyy'
                        value={date}
                        onChange={(newValue) => {
                            setDate(newValue);
                        }}
                    />
                </LocalizationProvider>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancelar</Button>
                <Button variant="contained" color="primary" onClick={async () => {
                    if (!date) return;
                    if (modalType === "expected") {
                        // set expected date
                        await updateInvoice(selectedInvoice?._id!, { expectedDate: date });
                    } else if (modalType === "charge") {
                        // set charge date
                        await updateInvoice(selectedInvoice?._id!, { charged: true, chargedDate: date });
                    } else {
                        // set pay date
                        await updateInvoice(selectedInvoice?._id!, { payed: true, payedDate: date });
                    }
                    onClose();
                    onSubmit();
                }}>Guardar</Button>
            </DialogActions>
        </Dialog>
    )
}

export default DateModal;
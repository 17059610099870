import { Button, FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm } from '../../../hooks/useForm';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { createProject } from '../../../services/proyectService';
import { Routes } from '../../../config/Routes';
import { useEffect } from 'react';


const NewProjectView = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { type } = location.state || {};
    const formattedType = type === 'project' ? 'Proyecto' : 'Obra';
    const initialValues = {
        type: type,  // Tipo de proyecto, como 'proyecto' o 'obra'
        userName: '',
        rut: '',
        legalName: '',
        date: new Date(),
        projectName: '',
        projectNumber: 0,
        location: '',
        commune: '',
        region: '',
        price: 0,
        currency: '',  // Unidad monetaria, como 'peso' o 'uf'
    }

    const [values, setValues, handleChange] = useForm(initialValues);

    useEffect(() => {
        if (!type) {
            navigate(Routes.Projects);
        }
    }, [type, navigate]);

    return (
        <div className="p-5 h-full overflow-auto">
            <IconButton className="mb-5" onClick={() => navigate(-1)}>
                <ArrowBackIcon />
            </IconButton>
            <div className="mb-5 text-xl">Crear {formattedType}</div>
            <div className='flex flex-col gap-2'>
                <TextField
                    onChange={handleChange}
                    id="userName"
                    name="userName"
                    label="Nombre Cliente"
                ></TextField>
                <LocalizationProvider dateAdapter={AdapterDateFns
                }>
                    <DesktopDatePicker
                        label="Fecha"
                        format='dd/MM/yyyy'
                        value={values.date}
                        onChange={(newValue) => {
                            setValues({ ...values, date: newValue! });
                        }}
                    />
                </LocalizationProvider>
                <TextField
                    onChange={handleChange}
                    id="projectName"
                    name="projectName"
                    label="Nombre Proyecto"
                ></TextField>
                <TextField
                    onChange={(e) => {
                        setValues({ ...values, projectNumber: Number(e.target.value) });
                    }}
                    value={values.projectNumber}
                    id="projectNumber"
                    name="projectNumber"
                    label="Número Proyecto"
                ></TextField>
                <TextField
                    onChange={handleChange}
                    id="rut"
                    name="rut"
                    label="Rut"
                ></TextField>
                <TextField
                    onChange={handleChange}
                    id="legalName"
                    name="legalName"
                    label="Razón Social"
                ></TextField>
                <TextField
                    onChange={handleChange}
                    id="location"
                    name="location"
                    label="Ubicación"
                ></TextField>
                <TextField
                    onChange={handleChange}
                    id="commune"
                    name="commune"
                    label="Comuna"
                ></TextField>
                <TextField
                    onChange={handleChange}
                    id="region"
                    name="region"
                    label="Región"
                ></TextField>
                <TextField
                    onChange={(e) => {
                        setValues({ ...values, price: Number(e.target.value) });
                    }
                    }
                    id="price"
                    name="price"
                    label="Precio"
                    type="number"
                ></TextField>
                <FormControl>
                    <InputLabel id="currency">Moneda</InputLabel>
                    <Select
                        labelId="currency"
                        id="currency"
                        label="Moneda"
                        value={values.currency}
                        onChange={(e) => {
                            setValues({ ...values, currency: e.target.value as string });
                        }}
                    >
                        <MenuItem value={'Pesos'}>Pesos</MenuItem>
                        <MenuItem value={'UF'}>UF</MenuItem>
                    </Select>
                </FormControl>

                <Button variant='contained' disabled={
                    values.projectNumber <= 0
                } onClick={
                    () => {
                        createProject(values).then((res) => {
                            if (res) {
                                alert('Proyecto creado correctamente');
                                navigate(-1);
                            }
                            else {
                                alert('Error al crear el proyecto');
                            }
                        })
                    }
                }>
                    Crear {formattedType}
                </Button>

            </div>
        </div>
    );
}

export default NewProjectView;
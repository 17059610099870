import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from "@mui/material";
import { DataGridPro, GridColDef, GridToolbar, esES } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { getInvoices } from "../../../services/invoiceService";
import { assignInvoiceToPaymentForm } from "../../../services/proyectService";

interface Props {
    open: boolean;
    onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
    selectedPaymentForm: PaymentFormUnwind | null;
}

const InvoicesModal: React.FC<Props> = ({
    open,
    onClose,
    selectedPaymentForm,
}) => {
    const columns: GridColDef[] = [
        { field: 'enterprise', headerName: 'Empresa', width: 150 },
        { field: 'dteType', headerName: 'Tipo DTE', width: 150 },
        { field: 'invoiceNumber', headerName: 'Numero Factura', width: 150 },
        { field: 'customerRut', headerName: 'Rut Cliente', width: 150 },
        { field: 'customerName', headerName: 'Nombre Cliente', width: 250 },
        {
            field: 'date', headerName: 'Fecha', width: 200
        },
        {
            field: 'receptionDate', headerName: 'Fecha Recepcion', width: 200
        },
        {
            field: 'acuseDate', headerName: 'Fecha Acuse', width: 200
        },
        { field: 'exentAmount', headerName: 'Monto Exento', width: 200 },
        { field: 'netAmount', headerName: 'Total Neto', width: 200 },
        { field: 'IVA', headerName: 'IVA', width: 200 },
        { field: 'total', headerName: 'Total', width: 200 },
    ];
    const [rows, setRows] = useState<Invoice[]>([]);
    const [filteredRows, setFilteredRows] = useState<Invoice[]>([]);
    const [invoiceNumber, setInvoiceNumber] = useState<number>(0);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (invoiceId: string, invoiceNumber: string) => {
        setLoading(true);
        // assign invoice to payment form
        await assignInvoiceToPaymentForm(selectedPaymentForm?._id!, selectedPaymentForm?.paymentKey!, invoiceId, selectedPaymentForm?.paymentPercentage!, selectedPaymentForm?.type!, selectedPaymentForm?.additionalName!, invoiceNumber);
        setLoading(false);
        onClose()
    }

    useEffect(() => {
        if (!selectedPaymentForm) return;
        // get only pending invoices
        getInvoices(true).then((data) => {
            setRows(data);
            setFilteredRows(data);
        })
    }, [selectedPaymentForm]);

    useEffect(() => {
        if (!invoiceNumber) {
            setFilteredRows(rows);
            return;
        }
        const filtered = rows.filter((row) => row.invoiceNumber === invoiceNumber);
        setFilteredRows(filtered);
    }, [invoiceNumber, rows]);
    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                style: {
                    minHeight: '80vh', // Ajusta la altura mínima del diálogo aquí
                    minWidth: '80vw', // Ajusta el ancho del diálogo aquí si necesitas un valor específico que no sea uno de los predefinidos
                },
            }}
        >
            <DialogTitle>Seleccione Factura a conciliar</DialogTitle>
            <DialogContent>
                <div className="mt-2 mb-2">
                    <TextField
                        label="Numero de Factura"
                        value={invoiceNumber}
                        onChange={(e) => setInvoiceNumber(Number(e.target.value))}
                    />
                </div>
                <Box>
                    <DataGridPro
                        rows={filteredRows}
                        columns={columns}
                        loading={loading}
                        getRowId={(row) => row._id}
                        autoHeight
                        pagination
                        pageSize={10}
                        components={{
                            Toolbar: GridToolbar,
                        }}
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        onRowDoubleClick={
                            (params) => {
                                handleSubmit(params.row._id, params.row.invoiceNumber);
                            }
                        }
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancelar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default InvoicesModal;
import { BrowserRouter, Route, Routes as AppRoutes } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material";
import { initLogin } from "./services/authService";
import { useEffect, useState } from "react";
import { mdiCircleSlice1 } from "@mdi/js";
import Icon from "@mdi/react";
import Layout from "./components/Layout";
import { Routes } from "./config/Routes";
import ProtectedRoute from "./components/ProtectedRoute";
import Login from "./components/views/Login/Login";
import "./App.css";
import Home from "./components/views/Home/Home";
import MainView from "./components/views/Proyects/MainView";
import NewProjectView from "./components/views/Proyects/NewProjectView";
import EditProjectView from "./components/views/Proyects/EditProjectView";
import AlertsMainView from "./components/views/Alerts/MainView";
import InvoicesView from "./components/views/Invoices/InvoicesView";
import EmployeeView from "./components/views/Employees/EmployeeView";
import AssistanceMainView from "./components/views/Assistance/MainView";

function App() {
  const theme = createTheme({
    palette: {
      background: {
        default: "#FFF",
      },
      primary: {
        main: "#ED6E11",
      },
      secondary: {
        main: "#BF549A",
      },
    },
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      initLogin().finally(() => setLoading(false));
    };
    init();
  }, []);
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Layout>
          {loading ? (
            <div className="h-full flex justify-center items-center">
              <div className="animate-spin">
                <Icon size={6} path={mdiCircleSlice1}></Icon>
              </div>
            </div>
          ) : (
            <AppRoutes>
              <Route
                path={Routes.Home}
                element={<ProtectedRoute element={<Home />}></ProtectedRoute>}
              ></Route>
              <Route path={Routes.Projects} element={<ProtectedRoute element={<MainView />}></ProtectedRoute>}></Route>
              <Route path={Routes.NewProject} element={<ProtectedRoute element={<NewProjectView />}></ProtectedRoute>}></Route>
              <Route path={Routes.EditProject} element={<ProtectedRoute element={<EditProjectView />}></ProtectedRoute>}></Route>
              <Route path={Routes.Alerts} element={<ProtectedRoute element={<AlertsMainView />}></ProtectedRoute>}></Route>
              <Route path={Routes.Invoices} element={<ProtectedRoute element={<InvoicesView></InvoicesView>}></ProtectedRoute>}></Route>
              <Route path={Routes.Employees} element={<ProtectedRoute element={<EmployeeView />}></ProtectedRoute>}></Route>
              <Route path={Routes.Assistance} element={<ProtectedRoute element={<AssistanceMainView />}></ProtectedRoute>}></Route>
              <Route path={Routes.Login} element={<Login />}></Route>
            </AppRoutes>
          )}
        </Layout>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
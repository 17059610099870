import { Card, Button, Select, FormControl, InputLabel, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { getInvoices, insertInvoicesFromSII } from "../../../services/invoiceService";
import { DataGridPro, GridColDef, GridToolbar, esES } from "@mui/x-data-grid-pro";
import handleGenerateExcel from "../../../utils/createInvoicesExcel";


const InvoicesView = () => {

  const columns: GridColDef[] = [
    { field: 'enterprise', headerName: 'Empresa', width: 150 },
    { field: 'dteType', headerName: 'Tipo DTE', width: 150 },
    { field: 'invoiceNumber', headerName: 'Numero Factura', width: 150 },
    { field: 'customerRut', headerName: 'Rut Cliente', width: 150 },
    { field: 'customerName', headerName: 'Nombre Cliente', width: 250 },
    {
      field: 'date', headerName: 'Fecha', width: 200,
      valueGetter(params) {
        return new Date(params.value).toLocaleDateString("es-ES")
      }
    },
    {
      field: 'receptionDate', headerName: 'Fecha Recepcion', width: 200
    },
    {
      field: 'acuseDate', headerName: 'Fecha Acuse', width: 200
    },
    {
      field: 'exentAmount', headerName: 'Monto Exento', width: 200,
      type: 'number',
      valueFormatter: ({ value }) => `$${value.toLocaleString("es-CL", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })}`
    },
    {
      field: 'netAmount', headerName: 'Total Neto', width: 200,
      type: 'number',
      valueFormatter: ({ value }) => `$${value.toLocaleString("es-CL", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })}`
    },
    {
      field: 'IVA', headerName: 'IVA', width: 200,
      type: 'number',
      valueFormatter: ({ value }) => `$${value.toLocaleString("es-CL", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })}`
    },
    {
      field: 'total', headerName: 'Total', width: 200,
      type: 'number',
      valueFormatter: ({ value }) => `$${value.toLocaleString("es-CL", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })}`
    },
    { field: 'referenceFolio', headerName: 'Folio Referencia', width: 200 },
    {
      field: 'charged', headerName: 'Cobrado', width: 200, valueGetter(params) {
        return params.value ? "Si" : "No"
      },
    },
    {
      field: 'chargedDate', headerName: 'Fecha Cobro', width: 200,
      valueGetter(params) {
        if (!params.value) return ""
        return new Date(params.value).toLocaleDateString("es-ES")
      }
    },
    { field: 'payed', headerName: 'Pagada', width: 200, valueGetter(params) { return params.value ? "Si" : "No" } },
    {
      field: 'payedDate', headerName: 'Fecha Pagada', width: 200,
      valueGetter(params) {
        if (!params.value) return ""
        return new Date(params.value).toLocaleDateString("es-ES")
      }
    }
  ];

  const [month, setMonth] = useState<string>("");
  const [year, setYear] = useState<string>("");
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [selectedRows, setSelectedRows] = useState<String[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchingInvoices, setSearchingInvoices] = useState<boolean>(false);

  useEffect(() => {
    getInvoices().then((data) => {
      setInvoices(data);
      setLoading(false);
    })
  }, [])

  return (
    <div className="h-full flex flex-col overflow-auto p-5">
      <h2 className="mb-5 text-xl">Facturas</h2>
      <div className="flex gap-2 mb-3">
        <FormControl>
          <InputLabel id="month">Mes</InputLabel>
          <Select
            sx={{ minWidth: 100 }}
            value={month}
            onChange={(e) => setMonth(e.target.value)}
            label="Mes"
            labelId="month"
            id="month"
          >
            <MenuItem value={"01"}>Enero</MenuItem>
            <MenuItem value={"02"}>Febrero</MenuItem>
            <MenuItem value={"03"}>Marzo</MenuItem>
            <MenuItem value={"04"}>Abril</MenuItem>
            <MenuItem value={"05"}>Mayo</MenuItem>
            <MenuItem value={"06"}>Junio</MenuItem>
            <MenuItem value={"07"}>Julio</MenuItem>
            <MenuItem value={"08"}>Agosto</MenuItem>
            <MenuItem value={"09"}>Septiembre</MenuItem>
            <MenuItem value={"10"}>Octubre</MenuItem>
            <MenuItem value={"11"}>Noviembre</MenuItem>
            <MenuItem value={"12"}>Diciembre</MenuItem>
          </Select>
        </FormControl>

        <FormControl>
          <InputLabel id="year">Año</InputLabel>
          <Select
            sx={{ minWidth: 100 }}
            value={year}
            onChange={(e) => setYear(e.target.value)}
            label="Año"
            labelId="year"
            id="year"
          >
            <MenuItem value={"2021"}>2021</MenuItem>
            <MenuItem value={"2022"}>2022</MenuItem>
            <MenuItem value={"2023"}>2023</MenuItem>
            <MenuItem value={"2024"}>2024</MenuItem>
            <MenuItem value={"2025"}>2025</MenuItem>
          </Select>
        </FormControl>

        <Button variant="contained" disabled={searchingInvoices} onClick={
          () => {
            setSearchingInvoices(true);
            insertInvoicesFromSII(month, year, "76290565-5").then((response) => {
              if (response) {
                alert("Facturas insertadas correctamente")
                setLoading(true);
                getInvoices().then((data) => {
                  setInvoices(data);
                  setLoading(false);
                })
              }
              else {
                alert("Error al insertar facturas")
              }
            }).finally(() => setSearchingInvoices(false))

          }
        }>Buscar Constructora FYRA </Button>
        <Button variant="contained" disabled={searchingInvoices} onClick={
          () => {
            setSearchingInvoices(true);
            insertInvoicesFromSII(month, year, "76644679-5").then((response) => {
              if (response) {
                alert("Facturas insertadas correctamente")
                setLoading(true);
                getInvoices().then((data) => {
                  setInvoices(data);
                  setLoading(false);
                })
              }
              else {
                alert("Error al insertar facturas")
              }
            }).finally(() => setSearchingInvoices(false))

          }
        }>Buscar FYRA Proyectos</Button>
        <Button variant="contained"
          onClick={
            async () => {
              let objectToExcel: Invoice[] = []
              invoices.forEach((r) => {
                if (selectedRows.includes(r._id)) {
                  objectToExcel.push(r)
                }
              })
              await handleGenerateExcel(objectToExcel)
            }}
        >
          Descargar excel
        </Button>
      </div>

      <Card>
        <DataGridPro

          sx={{
            padding: 2,
            '& .payed': {
              bgcolor: '#9ACD32',
            },
            '& .nullified': {
              bgcolor: '#FF0000',
            },
            '& .assigned': {
              bgcolor: '#FFAA33',
            },
          }}

          getRowClassName={(params) => {
            if (params.row.payed) {
              return 'payed';
            }
            if (params.row.nullified) {
              return 'nullified';
            }
            if (params.row.assigned) {
              return 'assigned';
            }
            return '';
          }}
          rows={invoices}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10, 25, 50, 100]}
          autoHeight
          pagination
          loading={loading}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          getRowId={(row) => row._id}
          components={{
            Toolbar: GridToolbar,
          }}

          onStateChange={(state) => {
            let selectedRowsToSet: String[] = []
            Object.keys(state.filter.visibleRowsLookup).forEach((key) => {
              if (state.filter.visibleRowsLookup[key]) {
                selectedRowsToSet.push(key)
              }
            })

            // Solo actualiza el estado si realmente cambió, para evitar problemas de re-renderizado
            if (JSON.stringify(selectedRowsToSet) !== JSON.stringify(selectedRows)) {
              setSelectedRows(selectedRowsToSet);
            }

          }
          }
        ></DataGridPro>
      </Card>
    </div>
  )
}

export default InvoicesView
import React, { useState } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import ConstructionProjectsView from "./ConstructionProjectsView";
import { AssitanceView } from "./AssitanceView";
import { EmployeeAssitanceView } from "./EmployeeAssistanceView";

const MainView: React.FC = () => {
    const [tab, setTab] = useState("assistance");

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setTab(newValue);
    };


    return (
        <div className="h-full flex flex-col overflow-auto">
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={tab} onChange={handleChange} variant="scrollable">
                    <Tab label="Revisar Asistencia" value="assistance" />
                    <Tab label="Asistencia de empleados" value="employee-assistance" />
                    <Tab label="Seleccionar Obras Asistibles" value="construction" />
                </Tabs>
            </Box>
            <div className="flex-1 overflow-auto p-4">
                {tab === "assistance" && <AssitanceView />}
                {tab === "employee-assistance" && <EmployeeAssitanceView />}
                {tab === "construction" && <ConstructionProjectsView />}
            </div>
        </div>
    );
};

export default MainView;

import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

const handleGenerateExcel = async (payments) => {
    // Crear un nuevo libro de trabajo
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Total Pagos');

    // Definir las columnas del Excel según el formato que has proporcionado
    worksheet.columns = [
        { header: 'Tipo DTE', key: 'dteType', width: 15 },
        { header: 'Numero Factura', key: 'invoiceNumber', width: 15 },
        { header: 'Rut Cliente', key: 'customerRut', width: 15 },
        { header: 'Nombre Cliente', key: 'customerName', width: 25 },
        { header: 'Fecha', key: 'date', width: 20 },
        { header: 'Fecha Recepcion', key: 'receptionDate', width: 20 },
        { header: 'Fecha Acuse', key: 'acuseDate', width: 20 },
        { header: 'Monto Exento', key: 'exentAmount', width: 20, style: { numFmt: '#,##0' } },
        { header: 'Total Neto', key: 'netAmount', width: 20, style: { numFmt: '#,##0' } },
        { header: 'IVA', key: 'IVA', width: 20, style: { numFmt: '#,##0' } },
        { header: 'Total', key: 'total', width: 20, style: { numFmt: '#,##0' } },
        { header: 'Cobrado', key: 'charged', width: 10 },
        { header: 'Fecha Cobro', key: 'chargedDate', width: 20 },
        { header: 'Fecha Esperada', key: 'expectedDate', width: 20 },
        { header: 'Tipo de Proyecto', key: 'projectType', width: 20 },
        { header: 'Nombre Proyecto', key: 'projectName', width: 30 },
        { header: 'Orden de Compra', key: 'oc', width: 20 },
        { header: 'Número de Recepción', key: 'receptionNumber', width: 20 },
        { header: 'Clave de Pago', key: 'paymentKey', width: 20 },
        { header: 'Porcentaje de Pago', key: 'paymentPercentage', width: 15, style: { numFmt: '0%' } },
    ];

    // Agregar filas a la hoja de trabajo
    payments.forEach((payment) => {
        worksheet.addRow({
            dteType: payment.dteType,
            invoiceNumber: payment.invoiceNumber,
            customerRut: payment.customerRut,
            customerName: payment.customerName,
            date: new Date(payment.date).toLocaleDateString("es-ES"),
            receptionDate: payment.receptionDate,
            acuseDate: payment.acuseDate,
            exentAmount: payment.exentAmount,
            netAmount: payment.netAmount,
            IVA: payment.IVA,
            total: payment.total,
            charged: payment.charged ? "Si" : "No",
            chargedDate: payment.chargedDate ? new Date(payment.chargedDate).toLocaleDateString("es-ES") : "",
            expectedDate: payment.expectedDate ? new Date(payment.expectedDate).toLocaleDateString("es-ES") : "",
            projectType: payment.projectType === 'project' ? 'Proyecto' :
                         payment.projectType === 'additional' ? 'Adicional' :
                         payment.projectType === 'construction' ? 'Obra' : 'N/A',
            projectName: payment.additionalName ? `${payment.projectName} - ${payment.additionalName}` : payment.projectName || "N/A",
            oc: payment.oc || "N/A",
            receptionNumber: payment.receptionNumber || "N/A",
            paymentKey: payment.paymentKey,
            paymentPercentage: payment.paymentPercentage / 100, // Se guarda como porcentaje
        });
    });

    // Generar el archivo Excel
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, 'ListadoPagos.xlsx');
};

export default handleGenerateExcel;

import { Button, Card, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { getInvoices } from "../../../services/invoiceService";
import { DataGridPro, GridColDef, GridToolbar, esES } from "@mui/x-data-grid-pro";
import DateModal from "./DateModal";
import handleGenerateExcel from "../../../utils/createPaymentsExcel";

const PendingPaymentView = () => {
    const [invoiceNumber, setInvoiceNumber] = useState<number>(0);
    const [selectedRows, setSelectedRows] = useState<String[]>([]);
    const columns: GridColDef[] = [
        { field: 'dteType', headerName: 'Tipo DTE', width: 150 },
        { field: 'invoiceNumber', headerName: 'Numero Factura', width: 150 },
        { field: 'customerRut', headerName: 'Rut Cliente', width: 150 },
        { field: 'customerName', headerName: 'Nombre Cliente', width: 250 },
        {
            field: 'date', headerName: 'Fecha', width: 200, valueGetter(params) {
                return new Date(params.value).toLocaleDateString("es-ES")
            },
        },
        {
            field: 'receptionDate', headerName: 'Fecha Recepcion', width: 200
        },
        {
            field: 'acuseDate', headerName: 'Fecha Acuse', width: 200
        },
        {
            field: 'exentAmount', headerName: 'Monto Exento', width: 200,
            type: 'number',
            valueFormatter: ({ value }) => `$${value.toLocaleString('es-CL', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            })}`
        },
        {
            field: 'netAmount', headerName: 'Total Neto', width: 200,
            type: 'number',
            valueFormatter: ({ value }) => `$${value.toLocaleString('es-CL', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            })}`
        },
        {
            field: 'IVA', headerName: 'IVA', width: 200,
            type: 'number',
            valueFormatter: ({ value }) => `$${value.toLocaleString('es-CL', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            })}`
        },
        {
            field: 'total', headerName: 'Total', width: 200,
            type: 'number',
            valueFormatter: ({ value }) => `$${value.toLocaleString('es-CL', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            })}`
        },
        {
            field: 'charged', headerName: 'Cobrado', width: 200, valueGetter(params) {
                return params.value ? "Si" : "No"
            },
        },
        {
            field: 'chargedDate', headerName: 'Fecha Cobro', width: 200,
            valueGetter(params) {
                if (!params.value) return ""
                return new Date(params.value).toLocaleDateString("es-ES")
            }
        },
        {
            field: 'expectedDate', headerName: 'Fecha Esperada', width: 200,
            valueGetter(params) {
                if (!params.value) return ""
                return new Date(params.value).toLocaleDateString("es-ES")
            }
        },
        {
            field: "projectType",
            headerName: "Tipo de Proyecto",
            minWidth: 150,
            flex: 1,
            valueGetter(params) {
                switch (params.row.projectType) {
                    case 'project':
                        return 'Proyecto';
                    case 'additional':
                        return 'Adicional';
                    case 'construction':
                        return 'Obra';
                    default:
                        return 'N/A';
                }
            },
        },
        {
            field: 'projectName', headerName: 'Nombre Proyecto', width: 200,
            valueGetter(params) {
                return params.row.additionalName ? `${params.row.projectName} - ${params.row.additionalName}` : params.row.projectName || "N/A";
            },
        },
        {
            field: 'oc', headerName: 'Orden de Compra', width: 200,
            valueGetter(params) {
                return params.row.oc || "N/A"
            },
        },
        {
            field: 'receptionNumber', headerName: 'Número de Recepción', width: 200,
            valueGetter(params) {
                return params.row.receptionNumber || "N/A"
            },
        },
        {
            field: 'paymentKey', headerName: 'Clave de Pago', width: 200
        },
        {
            field: 'paymentPercentage', headerName: 'Porcentaje de Pago', width: 200,
            type: 'number',
            valueFormatter: ({ value }) => `${value}%`
        },
        {
            field: 'payed', headerName: "Acciones", width: 200, renderCell: (params) => {
                return (
                    <div className="flex justify-center">
                        <Button variant="contained" color="primary"
                            onClick={
                                () => {
                                    setOldDate(null);
                                    setSelectedDateType('pay');
                                    setSelectedInvoice(params.row);
                                    setOpenDateModal(true);
                                }
                            }
                        >Pagado</Button>
                    </div>
                )
            }
        }
    ];

    const [loading, setLoading] = useState<boolean>(true);
    const [invoices, setInvoices] = useState<Invoice[]>([]);
    const [selectedDateType, setSelectedDateType] = useState<"expected" | "charge" | "pay">("expected");
    const [openDateModal, setOpenDateModal] = useState<boolean>(false);
    const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null);
    const [oldDate, setOldDate] = useState<Date | null>(null);

    const handleCellDoubleClick = (params: { field: string; row: Invoice }) => {
        if (params.field === 'chargedDate' || params.field === 'expectedDate') {
            setOldDate(params.row![params.field] ? new Date(params.row![params.field]!) : null);
            setSelectedDateType(params.field === 'chargedDate' ? 'charge' : 'expected');
            setSelectedInvoice(params.row);
            setOpenDateModal(true);
        }
    };

    const handleInvoiceChange = () => {
        setLoading(true);
        getInvoices(false, true).then((data) => {
            setInvoices(data);
            setLoading(false);
        })

    }
    useEffect(() => {
        handleInvoiceChange()
    }, [])


    return (

        <div className="h-full flex flex-col overflow-auto p-5">
            <div className="flex gap-2 mb-2">
                <h2 className="mb-5 text-xl">Facturas</h2>
                <TextField
                    label="Numero de Factura"
                    value={invoiceNumber}
                    onChange={(e) => setInvoiceNumber(Number(e.target.value))}
                />
                <Button variant="contained"
                    onClick={
                        async () => {
                            let objectToExcel: Invoice[] = []
                            invoices.forEach((r) => {
                                if (selectedRows.includes(r._id)) {
                                    objectToExcel.push(r)
                                }
                            })
                            await handleGenerateExcel(objectToExcel)
                        }
                    }>Descargar Excel</Button>
            </div>
            <Card>
                <DataGridPro
                    sx={{
                        '& .row-red': {
                            backgroundColor: 'rgba(255, 0, 0, 0.2)',  // Estilo para las filas con paymentDate < Date.now()
                        }
                    }}
                    rows={invoiceNumber !== 0 ? invoices.filter((invoice) => invoice.invoiceNumber === invoiceNumber) : invoices}
                    columns={columns}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    autoHeight
                    pagination
                    loading={loading}
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    getRowId={(row) => row._id}
                    getRowClassName={(params) => {
                        if (!params.row.expectedDate) return '';
                        const expectedDate = new Date(params.row.expectedDate);
                        return expectedDate < new Date() ? 'row-red' : '';
                    }}
                    components={{
                        Toolbar: GridToolbar,
                    }}
                    onCellDoubleClick={handleCellDoubleClick}
                    initialState={{
                        pagination: {
                            pageSize: 10,
                        },
                    }}
                    onStateChange={(state) => {
                        let selectedRowsToSet: String[] = []
                        Object.keys(state.filter.visibleRowsLookup).forEach((key) => {
                            if (state.filter.visibleRowsLookup[key]) {
                                selectedRowsToSet.push(key)
                            }
                        })
                        // Solo actualiza el estado si realmente cambió, para evitar problemas de re-renderizado
                        if (JSON.stringify(selectedRowsToSet) !== JSON.stringify(selectedRows)) {
                            setSelectedRows(selectedRowsToSet);
                        }
                    }
                    }
                ></DataGridPro>
                <DateModal
                    open={openDateModal}
                    onClose={() => setOpenDateModal(false)}
                    onSubmit={handleInvoiceChange}
                    modalType={selectedDateType}
                    selectedInvoice={selectedInvoice}
                    oldDate={oldDate}

                />

            </Card>
        </div>
    )
}

export default PendingPaymentView
import React, { useEffect, useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Button,
    FormControlLabel,
    Checkbox,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Chip,
    Alert,
} from "@mui/material";
import { useForm } from "../../../hooks/useForm";

interface Props {
    open: boolean;
    onClose: () => void;
    onSubmit: (values: {
        name: string;
        username: string;
        password: string;
        roles: string[];
        active: boolean;
    }) => void;
    employee: any | null;
    roleOptions: string[]; // Opciones de roles disponibles
    errorAlert: boolean;
    setErrorAlert: (value: boolean) => void;
}

const NewEmployeeModal: React.FC<Props> = ({
    open,
    onClose,
    onSubmit,
    employee,
    roleOptions,
    errorAlert,
    setErrorAlert,
}) => {
    const [values, setValues, handleChange] = useForm({
        name: "",
        username: "",
        password: "",
        roles: [] as string[], // Cambiado a arreglo
        active: true,
    });

    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        if (employee) {
            setValues({
                name: employee.name || "",
                username: employee.username || "",
                password: "", // No se inicializa la contraseña por seguridad
                roles: employee.roles || [],
                active: employee.active ?? true,
            });
        }
    }, [employee]);

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{employee ? "Editar Empleado" : "Nuevo Empleado"}</DialogTitle>
            {errorAlert && <Alert severity="error"
                onClose={() => setErrorAlert(false)}>Error al realizar operación</Alert>}
            <DialogContent>
                <TextField
                    label="Nombre"
                    id="name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Usuario"
                    id="username"
                    name="username"
                    value={values.username}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    disabled={!!employee} // Deshabilitar para edición
                />
                {!employee && (<>
                    <TextField
                        label="Contraseña"
                        id="password"
                        name="password"
                        type={showPassword ? "text" : "password"}
                        value={values.password}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        helperText={
                            !employee ? "Ingrese una contraseña para el nuevo empleado." : ""
                        }
                    />
                    <Button
                        onClick={() => setShowPassword(!showPassword)}
                        size="small"
                        sx={{ marginBottom: 2 }}
                    >
                        {showPassword ? "Ocultar Contraseña" : "Mostrar Contraseña"}
                    </Button>
                </>)}
                <FormControl fullWidth margin="normal">
                    <InputLabel>Roles</InputLabel>
                    <Select
                        multiple
                        value={values.roles}
                        onChange={(e) =>
                            setValues({
                                ...values,
                                roles: e.target.value as string[], // Asegura que sea un arreglo de strings
                            })
                        }
                        renderValue={(selected) => (
                            <div style={{ display: "flex", flexWrap: "wrap", gap: 5 }}>
                                {(selected as string[]).map((role) => (
                                    <Chip key={role} label={role} />
                                ))}
                            </div>
                        )}
                    >
                        {roleOptions.map((role) => (
                            <MenuItem key={role} value={role}>
                                {role}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={values.active}
                            onChange={(e) => setValues({ ...values, active: e.target.checked })}
                        />
                    }
                    label="Activo"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancelar</Button>
                <Button
                    variant="contained"
                    onClick={() => onSubmit(values)}
                    disabled={
                        !values.name ||
                        !values.username ||
                        !values.roles.length ||
                        (!employee && !values.password)
                    }
                >
                    Guardar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default NewEmployeeModal;

import axios from "./client";


const API_URL = process.env.REACT_APP_API_URL;

export const getProjectMarks = async (projectNumber: number) => {
  try {
    const { data } = await axios.get<{ message: string, assistMarks: AssistMark[]}>(
      `${API_URL}assistMarks/${projectNumber}`
    );
    return data.assistMarks
  } 
  catch (error) {
    alert((error as any).response.data.message)
    throw error;
  }
};

export const getEmployeeMarks = async (id: string) => {
  try {
    const { data } = await axios.get<{ message: string, assistMarks: AssistMark[]}>(
      `${API_URL}assistMarks/employee/${id}`
    );
    return data.assistMarks
  } 
  catch (error) {
    alert((error as any).response.data.message)
    throw error;
  }
};
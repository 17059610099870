import React, { useEffect, useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
} from "@mui/material";

interface EditPasswordModalProps {
    open: boolean;
    onClose: () => void;
    employee: any; // Puedes tiparlo como el modelo de Employee si lo tienes definido
    onSubmit: (username: string, password: string) => Promise<void>;
}

const EditPasswordModal: React.FC<EditPasswordModalProps> = ({
    open,
    onClose,
    employee,
    onSubmit,
}) => {
    const [values, setValues] = useState({
        username: "",
        password: "",
        confirmPassword: "",
    });

    const [error, setError] = useState("");

    useEffect(() => {
        if (employee) {
            setValues({
                username: employee.username,
                password: "",
                confirmPassword: "",
            });
        } else {
            setValues({
                username: "",
                password: "",
                confirmPassword: "",
            });
        }
    }, [employee]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    };

    const handleSubmit = async () => {
        if (values.password !== values.confirmPassword) {
            setError("Las contraseñas no coinciden.");
            return;
        }

        try {
            await onSubmit(values.username, values.password);
            setError("");
            onClose();
        } catch (err) {
            setError("Error al cambiar la contraseña. Intenta nuevamente.");
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Cambiar Contraseña</DialogTitle>
            <DialogContent>
                <Typography variant="subtitle1" gutterBottom>
                    Usuario: {values.username}
                </Typography>
                <TextField
                    fullWidth
                    margin="normal"
                    id="password"
                    label="Nueva Contraseña"
                    name="password"
                    type="password"
                    value={values.password}
                    onChange={handleChange}
                />
                <TextField
                    fullWidth
                    margin="normal"
                    id="confirmPassword"
                    label="Confirmar Contraseña"
                    name="confirmPassword"
                    type="password"
                    value={values.confirmPassword}
                    onChange={handleChange}
                />
                {error && (
                    <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                        {error}
                    </Typography>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancelar</Button>
                <Button
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={!values.password || !values.confirmPassword}
                >
                    Guardar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditPasswordModal;

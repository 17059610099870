import React from 'react';
import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ToggleButton
} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import PercentOutlinedIcon from '@mui/icons-material/PercentOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';

const formatDate = (date: Date) => {
  return new Intl.DateTimeFormat('es-CL', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  }).format(date);
};

const PaymentForms = ({
  paymentForms,
  editReceptionNumberIndex,
  editReceptionNumberValue,
  setEditReceptionNumberValue,
  editDateIndex,
  editDateValue,
  setEditDateValue,
  editPercentageIndex,
  editPercentageValue,
  setEditPercentageValue,
  editUfAdjustmentIndex,
  editUfAdjustmentValue,
  setEditUfAdjustmentValue,
  editNetUfIndex,
  editNetUfValue,
  setEditNetUfValue,
  editNetIndex,
  editNetValue,
  setEditNetValue,
  handlePaymentFormChange,
  addNewPaymentForm,
  removePaymentForm,
  editReceptionNumber,
  saveReceptionNumber,
  editDate,
  saveDate,
  editPercentage,
  savePercentage,
  editUfAdjustment,
  saveUfAdjustment,
  editNetUf,
  saveNetUf,
  editNet,
  saveNet,
  newPaymentForm,
  project,
  handleValueTypeToggle,
  toggleEditMode,
  valueType
}: {
  paymentForms: PaymentForm[];
  editReceptionNumberIndex: number | null;
  editReceptionNumberValue: string;
  setEditReceptionNumberValue: (value: string) => void;
  editDateIndex: number | null;
  editDateValue: Date | null;
  setEditDateValue: (value: Date) => void;
  editPercentageIndex: number | null;
  editPercentageValue: number | null;
  setEditPercentageValue: (value: number) => void;
  editUfAdjustmentIndex: number | null;
  editUfAdjustmentValue: number | null;
  setEditUfAdjustmentValue: (value: number) => void;
  editNetUfIndex: number | null;
  editNetUfValue: number | null;
  setEditNetUfValue: (value: number) => void;
  editNetIndex: number | null;
  editNetValue: number | null;
  setEditNetValue: (value: number) => void;
  handlePaymentFormChange: (field: string | number, value: string | number | Date) => void;
  addNewPaymentForm: () => void;
  removePaymentForm: (index: number) => void;
  editReceptionNumber: (index: number) => void;
  saveReceptionNumber: (index: number) => void;
  editDate: (index: number) => void;
  saveDate: (index: number) => void;
  editPercentage: (index: number) => void;
  savePercentage: (index: number) => void;
  editUfAdjustment: (index: number) => void;
  saveUfAdjustment: (index: number) => void;
  editNetUf: (index: number) => void;
  saveNetUf: (index: number) => void;
  editNet: (index: number) => void;
  saveNet: (index: number) => void;
  newPaymentForm: PaymentForm;
  project: Project | null;
  handleValueTypeToggle: () => void;
  toggleEditMode: boolean;
  valueType: 'percentage' | 'netUf';
}) => (
  <>
    <div className='flex gap-2'>
      <h3 className='mb-3'>Etapas de pago</h3>
      <p>
        Total porcentaje: {paymentForms.reduce((sum, form) => sum + form.percentage, 0)} %
      </p>
    </div>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <TableContainer component={Paper}>
        <Table aria-label="Tabla de Formas de Pago">
          <TableHead>
            <TableRow>
              <TableCell>Clave</TableCell>
              <TableCell>Numero Recepción</TableCell>
              <TableCell>Porcentaje</TableCell>
              <TableCell>Corrección Monetaria</TableCell>
              {project?.currency === "UF" && <TableCell>Neto UF</TableCell>}
              <TableCell>Neto</TableCell>
              <TableCell>IVA</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>Fecha</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paymentForms.map((form, index) => (
              <TableRow key={index}>
                <TableCell>{form.key}</TableCell>
                <TableCell>
                  {editReceptionNumberIndex === index ? (
                    <TextField
                      value={editReceptionNumberValue}
                      onChange={(e) => setEditReceptionNumberValue(e.target.value || '')}
                      size="small"
                      onBlur={() => saveReceptionNumber(index)}
                      fullWidth
                      disabled={!!form.invoice}
                    />
                  ) : (
                    <>
                      {form.receptionNumber}
                      <IconButton disabled={!!form.invoice} onClick={() => editReceptionNumber(index)}>
                        <EditIcon color={!!form.invoice ? "disabled" : "inherit"} />
                      </IconButton>
                    </>
                  )}
                </TableCell>
                <TableCell>
                  {editPercentageIndex === index ? (
                    <TextField
                      type="number"
                      value={editPercentageValue || ''}
                      onChange={(e) => setEditPercentageValue(parseFloat(e.target.value) || parseFloat(''))}
                      size="small"
                      onBlur={() => savePercentage(index)}
                      fullWidth
                      disabled={!!form.invoice}
                    />
                  ) : (
                    <>
                      {form.percentage}
                      <IconButton disabled={!!form.invoice} onClick={() => editPercentage(index)}>
                        <EditIcon color={!!form.invoice ? "disabled" : "inherit"} />
                      </IconButton>
                    </>
                  )}
                </TableCell>
                <TableCell>
                  {project?.currency === "UF" ? (
                    editUfAdjustmentIndex === index ? (
                      <TextField
                        type="number"
                        value={editUfAdjustmentValue || ''}
                        onChange={(e) => setEditUfAdjustmentValue(parseFloat(e.target.value) || parseFloat(''))}
                        size="small"
                        onBlur={() => saveUfAdjustment(index)}
                        fullWidth
                        disabled={!!form.invoice}
                      />
                    ) : (
                      <>
                        {form.ufAdjustment !== 0 ? form.ufAdjustment.toLocaleString("es-CL", {
                          maximumFractionDigits: 3,
                        }) : 'N/A'}
                        <IconButton disabled={!!form.invoice} onClick={() => editUfAdjustment(index)}>
                          <EditIcon color={!!form.invoice ? "disabled" : "inherit"} />
                        </IconButton>
                      </>
                    )
                  ) : 'N/A'}
                </TableCell>
                {project?.currency === "UF" && (
                  <TableCell>
                    {editNetUfIndex === index ? (
                      <TextField
                        type="number"
                        value={editNetUfValue || ''}
                        onChange={(e) => setEditNetUfValue(parseFloat(e.target.value) || parseFloat(''))}
                        size="small"
                        onBlur={() => saveNetUf(index)}
                        fullWidth
                        disabled={!!form.invoice}
                      />
                    ) : (
                      <>
                        {form.netUf?.toLocaleString("es-CL", {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                        <IconButton disabled={!!form.invoice} onClick={() => editNetUf(index)}>
                          <EditIcon color={!!form.invoice ? "disabled" : "inherit"} />
                        </IconButton>
                      </>
                    )}
                  </TableCell>
                )}
                <TableCell>
                  {project?.currency === "UF" ? (
                    <>
                      {`$${form.net.toLocaleString("es-CL", {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}`}
                    </>
                  ) : (
                    editNetIndex === index ? (
                      <TextField
                        type="number"
                        value={editNetValue || ''}
                        onChange={(e) => setEditNetValue(parseFloat(e.target.value) || parseFloat(''))}
                        size="small"
                        onBlur={() => saveNet(index)}
                        fullWidth
                        disabled={!!form.invoice}
                      />
                    ) : (
                      <>
                        {`$${form.net.toLocaleString("es-CL", {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}`}
                        <IconButton disabled={!!form.invoice} onClick={() => editNet(index)}>
                          <EditIcon color={!!form.invoice ? "disabled" : "inherit"} />
                        </IconButton>
                      </>
                    )
                  )}
                </TableCell>
                <TableCell>{`$${form.iva.toLocaleString("es-CL", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}`}</TableCell>
                <TableCell>{`$${form.total.toLocaleString("es-CL", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}`}</TableCell>
                <TableCell>
                  {editDateIndex === index ? (
                    <>
                      <DesktopDatePicker
                        format='dd/MM/yyyy'
                        value={editDateValue}
                        onChange={date => {
                          if (!!date) setEditDateValue(date);
                        }}
                      />
                      <IconButton onClick={() => saveDate(index)} disabled={!!form.invoice}>
                        <SaveIcon color={!!form.invoice ? "disabled" : "inherit"} />
                      </IconButton>
                    </>
                  ) : (
                    <>
                      {formatDate(new Date(form.date))}
                      <IconButton disabled={!!form.invoice} onClick={() => editDate(index)}>
                        <EditIcon color={!!form.invoice ? "disabled" : "inherit"} />
                      </IconButton>
                    </>
                  )}
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => removePaymentForm(index)} disabled={!!form.invoice}>
                    <DeleteIcon color={!!form.invoice ? "disabled" : "inherit"} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell>
                <TextField
                  value={newPaymentForm.key}
                  onChange={e => handlePaymentFormChange('key', e.target.value)}
                  size="small"
                  fullWidth
                />
              </TableCell>
              <TableCell>
                <TextField
                  value={newPaymentForm.receptionNumber}
                  onChange={e => handlePaymentFormChange('receptionNumber', e.target.value)}
                  size="small"
                  fullWidth
                />
              </TableCell>
              <TableCell>
                <Box display="flex" alignItems="center" sx={{ maxWidth: '120px' }}>
                  <TextField
                    type="number"
                    value={newPaymentForm.percentage}
                    onChange={(e) => handlePaymentFormChange('percentage', parseFloat(e.target.value))}
                    size="small"
                    fullWidth
                    disabled={toggleEditMode}
                    InputProps={{
                      endAdornment: (
                        <ToggleButton
                          value="check"
                          selected={valueType === 'netUf'}
                          onChange={handleValueTypeToggle}
                          style={{
                            height: '100%',
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            padding: '4px',
                            minWidth: '30px',
                          }}
                        >
                          {valueType === 'percentage' ? <PercentOutlinedIcon fontSize="small" /> : <PaidOutlinedIcon fontSize="small" />}
                        </ToggleButton>
                      ),
                    }}
                  />
                </Box>
              </TableCell>
              {project?.currency === "UF" ? (
                <TableCell>
                  <TextField
                    type="number"
                    value={newPaymentForm.ufAdjustment}
                    onChange={e => handlePaymentFormChange('ufAdjustment', parseFloat(e.target.value))}
                    size="small"
                    fullWidth
                  />
                </TableCell>
              ) : (
                <TableCell>N/A</TableCell>
              )}
              {project?.currency === "UF" ? (
                <TableCell>
                  <TextField
                    type="number"
                    value={newPaymentForm.netUf}
                    onChange={(e) => handlePaymentFormChange('netUf', parseFloat(e.target.value))}
                    size="small"
                    fullWidth
                    disabled={!toggleEditMode}
                  />
                </TableCell>
              ) : null}
              <TableCell>
                <TextField
                  type="number"
                  value={newPaymentForm.net}
                  onChange={(e) => handlePaymentFormChange('net', parseFloat(e.target.value))}
                  size="small"
                  fullWidth
                  disabled={project?.currency === 'UF' || (toggleEditMode && project?.currency === 'UF')}
                />
              </TableCell>
              <TableCell>{`$${newPaymentForm.iva.toLocaleString("es-CL", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}`}</TableCell>
              <TableCell>{`$${newPaymentForm.total.toLocaleString("es-CL", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}`}</TableCell>
              <TableCell>
                <DesktopDatePicker
                  format='dd/MM/yyyy'
                  value={newPaymentForm.date}
                  onChange={date => {
                    if (!!date) handlePaymentFormChange('date', date);
                  }}
                />
              </TableCell>
              <TableCell>
                <Button startIcon={<AddIcon />}
                  disabled={
                    !newPaymentForm.key ||
                    !newPaymentForm.date ||
                    newPaymentForm.percentage <= 0 ||
                    (project?.currency === 'UF' ? (newPaymentForm.netUf ?? 0) <= 0 : newPaymentForm.net <= 0) ||
                    isNaN(newPaymentForm.percentage) ||
                    (project?.currency === 'UF' ? isNaN(newPaymentForm.netUf ?? 0) : isNaN(newPaymentForm.net))
                  } onClick={addNewPaymentForm}>
                  Agregar
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </LocalizationProvider>
  </>
);

export default PaymentForms;

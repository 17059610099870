import React from 'react'
import { useState } from 'react'
import { Box, Tab, Tabs } from '@mui/material'
import PendingInvoicesView from './PendingInvoicesView';
import PendingPaymentView from './PendingPaymentView';


const MainView = () => {
    const [tab, setTab] = useState("pendingInvoice");
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setTab(newValue);
    };
    return (
        <div className="h-full flex flex-col overflow-auto">
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={tab} onChange={handleChange} variant="scrollable">
                    <Tab label="Por Facturar" value={"pendingInvoice"} />
                    <Tab label="Por Cobrar" value={"construction"} />
                </Tabs>
            </Box>
            <div className="flex-1 overflow-auto">
                {tab === "pendingInvoice" ? (
                    <PendingInvoicesView />) : (
                    <PendingPaymentView />
                )}
            </div>
        </div>
    )
}

export default MainView
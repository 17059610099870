import axios from "./client";

const API_URL = process.env.REACT_APP_API_URL;


export const fetchEmployees = async () => {
    try {
        const { data } = await axios.get<Employee[]>(`${API_URL}employees`);
        return data;
    } catch (error) {
        alert("Error encontrando lista de empleados")
        return [];
    }
}

export const addEmployee = async (employeeWithPassWord: Partial<EmployeeWithPassowrd>) => {
    try {
        await axios.post<Employee>(`${API_URL}employees`, employeeWithPassWord);
        
    }
    catch (error) {
       throw error;
    }
}

export const updateEmployee = async (id: string, employee: Partial<Employee>) => {
    try {
        await axios.patch<Employee>(`${API_URL}employees/${id}`, employee);
    }
    catch (error) {
        throw error;
    }
}

export const deleteEmployee = async (id: string) => {
    try {
        await axios.delete<Employee>(`${API_URL}employees/${id}`);
    }
    catch (error) {
        throw error;
    }
}
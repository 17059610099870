import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

const handleGenerateExcel = async (projects) => {
    // Crear un nuevo libro de trabajo
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Total Proyectos');
    worksheet.columns = [
        { header: 'Numero', key: 'projectNumber', width: 30 },
        { header: 'Nombre', key: 'projectName', width: 30 },
    ];
    projects.forEach((project) => {
        worksheet.addRow({
            projectNumber: project.projectNumber,
            projectName: project.projectName,
        });
    });

     // Generar archivo Excel y guardarlo
     const buffer = await workbook.xlsx.writeBuffer();
     const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
     saveAs(blob, 'Listado.xlsx');
}

export default handleGenerateExcel;
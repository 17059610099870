export enum Routes {
  Home = "/",
  Login = "/login",
  Projects = "/projects",
  NewProject = "/projects/new",
  EditProject = "/projects/edit/:id",
  Alerts = "/alerts",
  Invoices = "/invoices",
  Employees = "/employees", 
  Assistance = "/assistance",
}

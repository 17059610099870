import React, { useState, useEffect } from "react";
import { Button, Card, Alert } from "@mui/material";
import {
  fetchEmployees,
  addEmployee,
  updateEmployee,
  deleteEmployee,
} from "../../../services/employeeService";
import { changePassword } from "../../../services/authService";
import NewEmployeeModal from "./NewEmployeeModal";
import EditPasswordModal from "./EditPasswordModal";
import { DataGridPro, esES, GridColDef } from "@mui/x-data-grid-pro";

const AdministratorsView: React.FC = () => {
  const [employees, setEmployees] = useState([] as Employee[]);
  const [isLoading, setIsLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState<Employee | null>(null);
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);

  const loadEmployees = async () => {
    setIsLoading(true);
    const result = await fetchEmployees();
    // filtrar distinto de obreros
    setEmployees(result.filter((employee) => !employee.roles.includes("Obrero")));
    setIsLoading(false);
  };

  useEffect(() => {
    loadEmployees();
  }, []);

  const columns: GridColDef[] = [
    { field: "name", headerName: "Nombre", flex: 1 },
    { field: "username", headerName: "Usuario", flex: 1 },
    {
      field: "roles",
      headerName: "Roles",
      flex: 1,
      renderCell: (params) => (
        <div>
          {params.row.roles.map((role: string) => (
            <div key={role}>{role}</div>
          ))}
        </div>
      ),
    },
    {
      field: "status",
      headerName: "Activo",
      flex: 1,
      renderCell: (params) => (params.row.active ? "Sí" : "No"),
    },
    {
      field: "edit",
      headerName: "Editar",
      flex: 1,
      renderCell: (params) => (
        <Button
          variant="contained"
          onClick={() => {
            setSelectedEmployee(params.row);
            setModalOpen(true);
          }}
        >
          Editar
        </Button>
      ),
    },
    {
      field: "changePassword",
      headerName: "Cambiar Clave",
      flex: 1,
      renderCell: (params) => (
        <Button
          variant="contained"
          onClick={() => {
            setSelectedEmployee(params.row);
            setPasswordModalOpen(true);
          }}
        >
          Cambiar Contraseña
        </Button>
      ),
    },
    {
      field: "delete",
      headerName: "Eliminar",
      flex: 1,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="error"
          onClick={async () => {
            try {
              await deleteEmployee(params.row._id);
              setSuccessAlert(true);
              await loadEmployees();
            } catch (error) {
              setErrorAlert(true);
            }
          }}
        >
          Eliminar
        </Button>)
    }
  ];

  return (
    <div>
      <div className="flex justify-end mb-4">
        <Button
          variant="contained"
          onClick={() => {
            setSelectedEmployee(null);
            setModalOpen(true);
          }}
        >
          Agregar Usuario Administrativo
        </Button>
      </div>
      {successAlert && <Alert
        severity="success"
        onClose={() => setSuccessAlert(false)}
      >Operación exitosa</Alert>}
      {errorAlert && <Alert
        severity="error"
        onClose={() => setErrorAlert(false)}
      >Error al realizar operación</Alert>}
      <Card>
        <DataGridPro
          autoHeight
          columns={columns}
          rows={employees}
          loading={isLoading}
          getRowId={(row) => row._id}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        />
      </Card>
      <NewEmployeeModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        employee={selectedEmployee}
        onSubmit={async (values) => {
          try {
            if (selectedEmployee) {
              await updateEmployee(selectedEmployee._id, values);
            } else {
              await addEmployee(values);
            }
            setModalOpen(false);
            setSuccessAlert(true);
            await loadEmployees();
          } catch (error) {
            setErrorAlert(true);
          }
        }}
        roleOptions={["Asistencia"]}
        errorAlert={errorAlert}
        setErrorAlert={setErrorAlert}
      />
      <EditPasswordModal
        open={passwordModalOpen}
        onClose={() => setPasswordModalOpen(false)}
        employee={selectedEmployee}
        onSubmit={async (username: string, password: string) => {
          try {
            await changePassword(username, password);
            setPasswordModalOpen(false);
            setSuccessAlert(true);
          } catch (error) {
            setErrorAlert(true);
          }
        }}
      />
    </div>
  );
};

export default AdministratorsView;

import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

const handleGenerateExcel = async (invoices) => {
    // Crear un nuevo libro de trabajo
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Listado de Facturas');

    // Definir las columnas de acuerdo a la tabla que proporcionaste
    worksheet.columns = [
        { header: 'Empresa', key: 'enterprise', width: 20 },
        { header: 'Tipo DTE', key: 'dteType', width: 20 },
        { header: 'Numero Factura', key: 'invoiceNumber', width: 20 },
        { header: 'Rut Cliente', key: 'customerRut', width: 20 },
        { header: 'Nombre Cliente', key: 'customerName', width: 30 },
        { header: 'Fecha', key: 'date', width: 20 },
        { header: 'Fecha Recepcion', key: 'receptionDate', width: 20 },
        { header: 'Fecha Acuse', key: 'acuseDate', width: 20 },
        { header: 'Monto Exento', key: 'exentAmount', width: 20, style: { numFmt: '#,##0' } },
        { header: 'Total Neto', key: 'netAmount', width: 20, style: { numFmt: '#,##0' } },
        { header: 'IVA', key: 'IVA', width: 20, style: { numFmt: '#,##0' } },
        { header: 'Total', key: 'total', width: 20, style: { numFmt: '#,##0' } },
        { header: 'Folio Referencia', key: 'referenceFolio', width: 20 },
        { header: 'Cobrado', key: 'charged', width: 10 },
        { header: 'Fecha Cobro', key: 'chargedDate', width: 20 },
        { header: 'Pagada', key: 'payed', width: 10 },
        { header: 'Fecha Pagada', key: 'payedDate', width: 20 },
    ];

    // Agregar filas con los datos formateados
    invoices.forEach((invoice) => {
        worksheet.addRow({
            enterprise: invoice.enterprise,
            dteType: invoice.dteType,
            invoiceNumber: invoice.invoiceNumber,
            customerRut: invoice.customerRut,
            customerName: invoice.customerName,
            date: new Date(invoice.date).toLocaleDateString("es-ES"),
            receptionDate: invoice.receptionDate,
            acuseDate: invoice.acuseDate,
            exentAmount: invoice.exentAmount,
            netAmount: invoice.netAmount,
            IVA: invoice.IVA,
            total: invoice.total,
            referenceFolio: invoice.referenceFolio,
            charged: invoice.charged ? "Si" : "No",
            chargedDate: invoice.chargedDate ? new Date(invoice.chargedDate).toLocaleDateString("es-ES") : "",
            payed: invoice.payed ? "Si" : "No",
            payedDate: invoice.payedDate ? new Date(invoice.payedDate).toLocaleDateString("es-ES") : "",
        });
    });

    // Generar el archivo Excel y guardarlo
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, 'ListadoFacturas.xlsx');
};

export default handleGenerateExcel;

import { generateAssistanceExcel } from "../../../utils/createAssistanceExcel"
import { useState, useEffect } from "react"
import { Button } from "@mui/material";
import {
    DataGrid,
    GridColDef,
} from "@mui/x-data-grid";
import { getProjectMarks } from "../../../services/assistMarkService";
import { getProjects } from "../../../services/proyectService";
import { fetchEmployees } from "../../../services/employeeService";



export const AssitanceView = () => {
    const [projects, setProjects] = useState<Project[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [ workers, setWorkers ] = useState<Employee[]>([]);
    const [ loadingExcel, setLoadingExcel ] = useState<boolean>(false);

    // Cargar proyectos del tipo "construction"
    const loadProjects = async () => {
        setLoading(true);
        const data = await getProjects("construction", "Si Adjudicado");
        setProjects(data);

        const employees = await fetchEmployees();
        setWorkers(employees.filter((employee) => employee.roles.includes("Obrero")));
        setLoading(false);
        
    };

    useEffect(() => {
        loadProjects();
    }, []);

    const handleGenerateAssistance = async (project: Project) => {
        try {
            setLoadingExcel(true)
            const assistance: AssistMark[] = await getProjectMarks(project.projectNumber)
            await generateAssistanceExcel(project, assistance, workers)
            setLoadingExcel(false)
        } catch (error) {
            setLoadingExcel(false)
        }
    }


    // Columnas de la tabla
    const columns: GridColDef[] = [
        { field: "projectName", headerName: "Nombre del Proyecto", flex: 1 },
        { field: "location", headerName: "Dirección", flex: 1 },
        {
            field: "actions",
            headerName: "Acciones",
            flex: 1,
            sortable: false,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    size="small"
                    disabled={loadingExcel}
                    onClick={() => {handleGenerateAssistance(params.row)}}
                >
                    Descargar Asistencia
                </Button>
            ),
        },
    ];


    return (
        <div className="p-4">
            <h1>Proyectos con Asistencia</h1>
            <div style={{ height: 600, width: "100%" }}>
                <DataGrid
                    rows={projects.filter((project) => {return !!project.takesAssistance})}
                    columns={columns}
                    pageSize={10}
                    getRowId={(row) => row._id}
                    loading={loading}
                />
            </div>
        </div>
    )
}

import axios from "./client";

const API_URL = process.env.REACT_APP_API_URL;

export const insertInvoicesFromSII = async (month: string, year: string, enterprise: string) => {
    try {
        await axios.get(`${API_URL}invoices/insert/${month}/${year}/${enterprise}`);
        return true;
    }
    catch (error) {
        return false;
    }
}

export const getInvoices = async (pending?: boolean, unpaid?: boolean) => {

    try {
        let params;
        if (pending) {
            params = { pending: true }
        }
        if (unpaid) {
            params = { unpaid: true }
        }
        const { data } = await axios.get<Invoice[]>(`${API_URL}invoices`, { params });
        return data;
    } catch (error) {
        console.error('Error fetching invoices:', error);
        return [];
    }
}

export const updateInvoice = async (id: string, invoice: Partial<Invoice>) => {
    try {
        await axios.patch<Invoice>(`${API_URL}invoices/${id}`, invoice);
        return true;
    }
    catch (error) {
        return false;
    }
}
import axios from "axios";
import { AuthActions } from "../reducers/AuthReducer";
import store from "../store";
import client from "./client";

const API_URL = process.env.REACT_APP_API_URL;

export const login = async (username: string, password: string) => {
  try {
  const {
    data: { token, user },
  } = await axios.post<{ token: string, user: User}>(
    `${API_URL}auth/login`,
    {
      username,
      password,
    }
  );
  if(user.roles.includes("Obrero")) {
    alert("No tienes permisos para acceder a esta aplicación")
    throw new Error("No tienes permisos para acceder a esta aplicación")
  }
  store.dispatch({ type: AuthActions.SetSession, payload: { token } });
  store.dispatch({ type: AuthActions.SetUser, payload: { user } });
  localStorage.setItem("token", token);
  localStorage.setItem("user", JSON.stringify(user));
  }
  catch (error) {
    alert((error as any).response.data)
  }
};

export const initLogin = async () => {
  const token = localStorage.getItem("token");
  const user = localStorage.getItem("user");

  if (token && user) {
    try {
      client.get(`${API_URL}auth/validate`, {
        headers: {
          "x-auth-token": token,
        },
      });
      store.dispatch({ type: AuthActions.SetSession, payload: { token } });
      store.dispatch({ type: AuthActions.SetUser, payload: { user: JSON.parse(user) } });
    } catch (error) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      store.dispatch({ type: AuthActions.DelSession, payload: null });
      store.dispatch({ type: AuthActions.DelUser, payload: null });
    }
  }
};

export const changePassword = async (username: string, password: string) => {
  try {
    await client.post(`${API_URL}auth/passwordChange`, {
      username,
      password,
    });
   
  } catch (error) {
   throw new Error((error as any).response.data)
  }
}
import React, { useState } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import WorkersView from "./WorkersView";
import AdministratorsView from "./AdministratorsView";
import { useAppSelector } from "../../../hooks/storeHooks";

const EmployeeView: React.FC = () => {
    const [tab, setTab] = useState("worker");

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setTab(newValue);
    };

    // Obtener los roles del usuario desde el store
    const userRoles = useAppSelector((s) => s.authReducer.user?.roles || []);
    const isOwner = userRoles.includes("Dueño");

    return (
        <div className="h-full flex flex-col overflow-auto">
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={tab} onChange={handleChange} variant="scrollable">
                    {/* Tab para crear obreros */}
                    <Tab label="Crear Obreros" value="worker" />
                    {/* Tab para crear usuarios administrativos (solo accesible para dueños) */}
                    {isOwner && <Tab label="Crear Administrativos" value="administrator" />}
                </Tabs>
            </Box>
            <div className="flex-1 overflow-auto p-4">
                {tab === "worker" && <WorkersView />}
                {tab === "administrator" && isOwner && <AdministratorsView />}
            </div>
        </div>
    );
};

export default EmployeeView;

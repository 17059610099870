import { ListItemIcon, ListItemText, AppBar, Toolbar, IconButton, Drawer } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { mdiLogoutVariant, mdiMenu } from "@mdi/js";
import Icon from "@mdi/react";
import { useState } from "react";
import { Routes } from "../config/Routes";
import { AuthActions } from "../reducers/AuthReducer";
import store from "../store";
import { useAppSelector } from "../hooks/storeHooks";

export const Menu: React.FC = () => {
    const history = useLocation();
    const [isNavOpen, setIsNavOpen] = useState(false);
    const route = history.pathname;

    // Obtener el usuario desde el store
    const user = useAppSelector((s) => s.authReducer.user);

    const handleLogout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        store.dispatch({ type: AuthActions.DelSession, payload: null });
        store.dispatch({ type: AuthActions.DelUser, payload: null });
        return;
    };

    // Determinar los roles del usuario
    const userRoles = user?.roles || [];
    const isAssistance = userRoles.includes("Asistencia");
    const isOwner = userRoles.includes("Dueño");

    return (
        <div>
            <AppBar position="fixed">
                <Toolbar className="flex justify-between">
                    <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => setIsNavOpen(true)}>
                        <Icon path={mdiMenu} size={1} />
                    </IconButton>
                </Toolbar>
            </AppBar>

            {/* Mobile Drawer */}
            <Drawer
                anchor="left"
                PaperProps={{ sx: { backgroundColor: "#ED6E11", color: "white" } }}
                open={isNavOpen}
                onClose={() => setIsNavOpen(false)}
            >
                <div className="flex flex-col space-y-4 p-4">
                    {/* Link a Inicio */}
                    <Link to={Routes.Home}>
                        <div onClick={() => setIsNavOpen(false)} className={`${route === Routes.Home ? "text-pineBlack" : ""}`}>Inicio</div>
                    </Link>

                    {/* Link a Alertas */}
                    {isOwner && (
                        <Link to={Routes.Alerts}>
                            <div onClick={() => setIsNavOpen(false)} className={`${route === Routes.Alerts ? "text-pineBlack" : ""}`}>Alertas</div>
                        </Link>
                    )}

                    {/* Link a Proyectos / Obras */}
                    {isOwner && (
                        <Link to={Routes.Projects}>
                            <div onClick={() => setIsNavOpen(false)} className={`${route === Routes.Projects ? "text-pineBlack" : ""}`}>Proyectos / Obras</div>
                        </Link>
                    )}

                    {/* Link a Facturas */}
                    {isOwner && (
                        <Link to={Routes.Invoices}>
                            <div onClick={() => setIsNavOpen(false)} className={`${route === Routes.Invoices ? "text-pineBlack" : ""}`}>Facturas</div>
                        </Link>
                    )}

                    {/* Link a Empleados */}
                    {(isAssistance || isOwner) && (
                        <Link to={Routes.Employees}>
                            <div onClick={() => setIsNavOpen(false)} className={`${route === Routes.Employees ? "text-pineBlack" : ""}`}>Empleados</div>
                        </Link>
                    )}
                    {/* Link a Asistencia */
                        (isAssistance || isOwner) && (
                            <Link to={Routes.Assistance}>
                                <div onClick={() => setIsNavOpen(false)} className={`${route === Routes.Assistance ? "text-pineBlack" : ""}`}>Asistencia</div>
                            </Link>
                        )}

                    {/* Logout */}
                    <div onClick={handleLogout} style={{ display: 'flex', alignItems: 'center', cursor: "pointer" }}>
                        <ListItemText>Cerrar sesión</ListItemText>
                        <ListItemIcon sx={{ color: "inherit", minWidth: '0', marginRight: '8px' }}>
                            <Icon className="w-7" path={mdiLogoutVariant}></Icon>
                        </ListItemIcon>
                    </div>
                </div>
            </Drawer>
        </div>
    );
};

import React, { useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ToggleButton,
  Switch
} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import PercentOutlinedIcon from '@mui/icons-material/PercentOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';

interface AdditionalFormsProps {
  additional: Additional;
  handleAdditionalFormChange: (field: string, value: any) => void;
  addNewAdditionalForm?: () => void;
  removeAdditionalForm?: () => void;
  title: string;
  showApproved: boolean;
}

const formatCurrency = (value: number, currency: string) => {
  if (currency === 'UF') {
    return value.toLocaleString('es-CL');
  }
  return new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' }).format(value);
};

const formatDate = (date: Date) => {
  return new Intl.DateTimeFormat('es-CL', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  }).format(date);
};

const AdditionalForms: React.FC<AdditionalFormsProps> = ({
  additional,
  handleAdditionalFormChange,
  addNewAdditionalForm,
  removeAdditionalForm,
  title,
  showApproved
}) => {
  const [editReceptionNumberIndex, setEditReceptionNumberIndex] = useState<number | null>(null);
  const [editReceptionNumberValue, setEditReceptionNumberValue] = useState<string>('');
  const [editDateIndex, setEditDateIndex] = useState<number | null>(null);
  const [editDateValue, setEditDateValue] = useState<Date | null>(null);
  const [editPercentageIndex, setEditPercentageIndex] = useState<number | null>(null);
  const [editPercentageValue, setEditPercentageValue] = useState<number | null>(null);
  const [editUfAdjustmentIndex, setEditUfAdjustmentIndex] = useState<number | null>(null);
  const [editUfAdjustmentValue, setEditUfAdjustmentValue] = useState<number | null>(null);
  const [editNetUfIndex, setEditNetUfIndex] = useState<number | null>(null);
  const [editNetUfValue, setEditNetUfValue] = useState<number | null>(null);
  const [editNetIndex, setEditNetIndex] = useState<number | null>(null);
  const [editNetValue, setEditNetValue] = useState<number | null>(null);
  const [isEditingOC, setIsEditingOC] = useState<boolean>(false);
  const [newPaymentForm, setNewPaymentForm] = useState<PaymentForm>({
    key: '',
    receptionNumber: '',
    percentage: 0,
    ufAdjustment: 0,
    net: 0,
    netUf: 0,
    iva: 0,
    total: 0,
    date: new Date(),
  });
  const [toggleEditMode, setToggleEditMode] = useState<boolean>(false);
  const [valueType, setValueType] = useState<'percentage' | 'net' | 'netUf'>('percentage');

  const isApproved = additional.approvedStatus === "Si Adjudicado" || additional.approvedStatus === "Terminado"

  const handleValueTypeToggle = () => {
    if (additional.currency === 'UF') {
      setValueType(prev => prev === 'percentage' ? 'netUf' : 'percentage');
    } else {
      setValueType(prev => prev === 'percentage' ? 'net' : 'percentage');
    }
    setToggleEditMode(!toggleEditMode);
  };

  const handlePaymentFormChange = (field: string | number, value: string | number | Date) => {
    const update = { ...newPaymentForm, [field]: value || '' };
    if (field === 'percentage' && !toggleEditMode) {
      let baseAmount = additional.price;
      if (additional.currency === 'UF') {
        baseAmount = baseAmount * newPaymentForm.ufAdjustment;
      }
      const net = baseAmount * (value as number / 100);
      const iva = net * 0.19;
      const total = net + iva;
      update.net = parseFloat(net.toFixed(2));
      update.iva = parseFloat(iva.toFixed(2));
      update.total = parseFloat(total.toFixed(2));
      if (additional.currency === 'UF') {
        const netUf = net / newPaymentForm.ufAdjustment;
        update.netUf = parseFloat(netUf.toFixed(2));
      }
    }
    if (field === 'ufAdjustment' && !toggleEditMode) {
      let baseAmount = additional.price;
      baseAmount = baseAmount * (value as number);
      const net = baseAmount * (newPaymentForm.percentage / 100);
      const iva = net * 0.19;
      const total = net + iva;
      update.net = parseFloat(net.toFixed(2));
      update.iva = parseFloat(iva.toFixed(2));
      update.total = parseFloat(total.toFixed(2));
      if (additional.currency === 'UF') {
        const netUf = net / (value as number);
        update.netUf = parseFloat(netUf.toFixed(2));
      }
    }
    if (field === 'net' && toggleEditMode) {
      const iva = (value as number) * 0.19;
      const total = (value as number) + iva;
      let baseAmount = additional.price;
      if (additional.currency === 'UF') {
        baseAmount = baseAmount * newPaymentForm.ufAdjustment;
      }
      const percentage = ((value as number) / baseAmount) * 100;
      update.iva = parseFloat(iva.toFixed(2));
      update.total = parseFloat(total.toFixed(2));
      update.percentage = parseFloat(percentage.toFixed(2));
      if (additional.currency === 'UF') {
        const netUf = (value as number) / newPaymentForm.ufAdjustment;
        update.netUf = parseFloat(netUf.toFixed(2));
      }
    }
    if (field === 'netUf' && toggleEditMode) {
      let baseAmount = additional.price;
      const net = (value as number) * newPaymentForm.ufAdjustment;
      const iva = net * 0.19;
      const total = net + iva;
      const percentage = ((net / newPaymentForm.ufAdjustment) / baseAmount) * 100;
      update.net = parseFloat(net.toFixed(2));
      update.iva = parseFloat(iva.toFixed(2));
      update.total = parseFloat(total.toFixed(2));
      update.percentage = parseFloat(percentage.toFixed(2));
    }
    if (field === 'ufAdjustment' && toggleEditMode) {
      let baseAmount = additional.price;
      baseAmount = baseAmount * (value as number);
      const percentage = (newPaymentForm.net / baseAmount) * 100;
      update.percentage = parseFloat(percentage.toFixed(2));
      if (additional.currency === 'UF') {
        const netUf = newPaymentForm.net / (value as number);
        update.netUf = parseFloat(netUf.toFixed(2));
      }
    }
    setNewPaymentForm(update);
  };

  const validatePaymentForm = (form: PaymentForm) => {
    return (
      !isNaN(form.percentage) &&
      !isNaN(form.net) &&
      !isNaN(form.iva) &&
      !isNaN(form.total) &&
      form.percentage > 0 &&
      form.net > 0 &&
      form.iva > 0 &&
      form.total > 0 &&
      (additional.currency === 'UF' ? form.netUf !== undefined && form.netUf > 0 : true)
    );
  };

  const addNewPaymentForm = () => {
    if (!validatePaymentForm(newPaymentForm)) {
      alert('Todos los valores deben ser mayores a 0.');
      return;
    }
    if (totalPercentage + newPaymentForm.percentage > 100) {
      alert('El total de porcentajes no puede execer el 100%.');
      return;
    }
    const updatedAdditional = {
      ...additional,
      paymentForm: [...additional.paymentForm, { ...newPaymentForm, netUf: newPaymentForm.netUf ?? 0 }],
    };
    handleAdditionalFormChange('paymentForm', updatedAdditional.paymentForm);
    setNewPaymentForm({
      key: '',
      receptionNumber: '',
      percentage: 0,
      ufAdjustment: 0,
      net: 0,
      netUf: 0,
      iva: 0,
      total: 0,
      date: new Date(),
    });
  };

  const removePaymentForm = (index: number) => {
    const updatedPaymentForms = additional.paymentForm.filter((_, i) => i !== index);
    handleAdditionalFormChange('paymentForm', updatedPaymentForms);
  };

  const editReceptionNumber = (index: number) => {
    setEditReceptionNumberIndex(index);
    setEditReceptionNumberValue(additional.paymentForm[index].receptionNumber);
  };

  const saveReceptionNumber = (index: number) => {
    const updatedForms = additional.paymentForm.map((form, i) =>
      i === index ? { ...form, receptionNumber: editReceptionNumberValue } : form
    );
    handleAdditionalFormChange('paymentForm', updatedForms);
    setEditReceptionNumberIndex(null);
    setEditReceptionNumberValue('');
  };

  const editDate = (index: number) => {
    setEditDateValue(new Date(additional.paymentForm[index].date));
    setEditDateIndex(index);
  };

  const saveDate = (index: number) => {
    const updatedForms = additional.paymentForm.map((form, i) =>
      i === index ? { ...form, date: new Date(editDateValue!) } : form
    );
    handleAdditionalFormChange('paymentForm', updatedForms);
    setEditDateIndex(null);
    setEditDateValue(null);
  };

  const editPercentage = (index: number) => {
    setEditPercentageValue(additional.paymentForm[index].percentage);
    setEditPercentageIndex(index);
  };

  const savePercentage = (index: number) => {
    const updatedForms = additional.paymentForm.map((form, i) => {
      if (i === index) {
        let baseAmount = additional.price;
        if (additional.currency === 'UF') {
          baseAmount = baseAmount * Number(form.ufAdjustment);
        }
        const net = baseAmount * (editPercentageValue! / 100);
        const iva = net * 0.19;
        const total = net + iva;
        const netUf = additional.currency === 'UF' ? net / form.ufAdjustment : net;
        return {
          ...form,
          percentage: editPercentageValue!,
          net: parseFloat(net.toFixed(2)),
          iva: parseFloat(iva.toFixed(2)),
          total: parseFloat(total.toFixed(2)),
          netUf: parseFloat(netUf.toFixed(2))
        };
      }
      return form;
    });

    const totalUpdatedPercentage = updatedForms.reduce((sum, form) => sum + form.percentage, 0);
    if (totalUpdatedPercentage > 100) {
      alert('No se puede exceder el 100%.');
      return;
    }

    if (!updatedForms.every(validatePaymentForm)) {
      alert('El campo debe ser mayor a 0.');
      return;
    }

    handleAdditionalFormChange('paymentForm', updatedForms);
    setEditPercentageIndex(null);
    setEditPercentageValue(null);
  };

  const editUfAdjustment = (index: number) => {
    setEditUfAdjustmentValue(Number(additional.paymentForm[index].ufAdjustment));
    setEditUfAdjustmentIndex(index);
  };

  const saveUfAdjustment = (index: number) => {
    const updatedForms = additional.paymentForm.map((form, i) => {
      if (i === index) {
        let baseAmount = additional.price;
        baseAmount = baseAmount * Number(editUfAdjustmentValue);
        const net = baseAmount * (form.percentage / 100);
        const iva = net * 0.19;
        const total = net + iva;
        const netUf = additional.currency === 'UF' ? net / editUfAdjustmentValue! : net;
        return {
          ...form,
          ufAdjustment: editUfAdjustmentValue!,
          net: parseFloat(net.toFixed(2)),
          iva: parseFloat(iva.toFixed(2)),
          total: parseFloat(total.toFixed(2)),
          netUf: parseFloat(netUf.toFixed(2))
        };
      }
      return form;
    });

    if (!updatedForms.every(validatePaymentForm)) {
      alert('El campo debe ser mayor a 0.');
      return;
    }

    handleAdditionalFormChange('paymentForm', updatedForms);
    setEditUfAdjustmentIndex(null);
    setEditUfAdjustmentValue(null);
  };

  const editNetUf = (index: number) => {
    setEditNetUfValue(additional.paymentForm[index].netUf ?? 0);
    setEditNetUfIndex(index);
  };

  const saveNetUf = (index: number) => {
    const updatedForms = additional.paymentForm.map((form, i) => {
      if (i === index) {
        const net = editNetUfValue! * form.ufAdjustment;
        const iva = net * 0.19;
        const total = net + iva;
        const percentage = (net / form.ufAdjustment) / additional.price * 100;
        return {
          ...form,
          netUf: editNetUfValue!,
          net: parseFloat(net.toFixed(2)),
          iva: parseFloat(iva.toFixed(2)),
          total: parseFloat(total.toFixed(2)),
          percentage: parseFloat(percentage.toFixed(2))
        };
      }
      return form;
    });

    if (!updatedForms.every(validatePaymentForm)) {
      alert('El campo debe ser mayor a 0.');
      return;
    }

    handleAdditionalFormChange('paymentForm', updatedForms);
    setEditNetUfIndex(null);
    setEditNetUfValue(null);
  };

  const editNet = (index: number) => {
    setEditNetValue(additional.paymentForm[index].net);
    setEditNetIndex(index);
  };

  const saveNet = (index: number) => {
    const updatedForms = additional.paymentForm.map((form, i) => {
      if (i === index) {
        const iva = (editNetValue as number) * 0.19;
        const total = (editNetValue as number) + iva;
        let baseAmount = additional.price;
        if (additional.currency === 'UF') {
          baseAmount = baseAmount * Number(form.ufAdjustment);
        }
        const percentage = ((editNetValue as number) / baseAmount) * 100;
        const netUf = additional.currency === 'UF' ? (editNetValue as number) / form.ufAdjustment : undefined;
        return {
          ...form,
          net: editNetValue!,
          iva: parseFloat(iva.toFixed(2)),
          total: parseFloat(total.toFixed(2)),
          percentage: parseFloat(percentage.toFixed(2)),
          netUf: additional.currency === 'UF' ? parseFloat(netUf!.toFixed(2)) : undefined
        };
      }
      return form;
    });

    if (!updatedForms.every(validatePaymentForm)) {
      alert('El campo debe ser mayor a 0.');
      return;
    }

    handleAdditionalFormChange('paymentForm', updatedForms);
    setEditNetIndex(null);
    setEditNetValue(null);
  };

  const toggleEditOC = () => {
    setIsEditingOC(!isEditingOC);
  };

  const totalPercentage = additional.paymentForm.reduce((sum, form) => sum + form.percentage, 0);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {title !== "Agregar Nuevo Adicional" && (
        <div className='flex gap-2'>
          <h3 className='mb-3'>{title}</h3>
        </div>
      )}
      {title !== "Agregar Nuevo Adicional" && (
        <TableContainer component={Paper}>
          <Table aria-label="Tabla de Adicionales" sx={{ minWidth: 650, marginBottom: 4 }}>
            <TableHead>
              <TableRow>
                <TableCell>Nombre Adicional</TableCell>
                <TableCell>Precio</TableCell>
                <TableCell>Moneda</TableCell>
                <TableCell>Fecha</TableCell>
                <TableCell>Orden de Compra</TableCell>
                {showApproved && <TableCell>Adjudicado</TableCell>}
                {!isApproved && <TableCell>Acciones</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  {additional.approved ? (
                    isApproved
                  ) : (
                    <TextField
                      label="Nombre Adicional"
                      value={additional.additionalName}
                      onChange={e => handleAdditionalFormChange('additionalName', e.target.value)}
                      size="small"
                      fullWidth
                    />
                  )}
                </TableCell>
                <TableCell>
                  {isApproved ? (
                    formatCurrency(additional.price, additional.currency)
                  ) : (
                    <TextField
                      label="Precio"
                      type="number"
                      value={additional.price}
                      onChange={e => handleAdditionalFormChange('price', parseFloat(e.target.value))}
                      size="small"
                      fullWidth
                    />
                  )}
                </TableCell>
                <TableCell>
                  {isApproved ? (
                    additional.currency
                  ) : (
                    <FormControl fullWidth size="small">
                      <InputLabel id="currency">Moneda</InputLabel>
                      <Select
                        labelId="currency"
                        id="currency"
                        value={additional.currency}
                        onChange={e => handleAdditionalFormChange('currency', e.target.value)}
                      >
                        <MenuItem value={'Pesos'}>Pesos</MenuItem>
                        <MenuItem value={'UF'}>UF</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                </TableCell>
                <TableCell>
                  {isApproved ? (
                    formatDate(new Date(additional.date))
                  ) : (
                    <DesktopDatePicker
                      format='dd/MM/yyyy'
                      value={additional.date ? new Date(additional.date) : new Date()}
                      onChange={date => handleAdditionalFormChange('date', date as Date)}
                    />
                  )}
                </TableCell>
                <TableCell>
                  <Box display="flex" alignItems="center">
                    {isEditingOC || !isApproved ? (
                      <TextField
                        label="Orden de Compra"
                        value={additional.oc}
                        onChange={e => handleAdditionalFormChange('oc', e.target.value)}
                        size="small"
                        fullWidth
                      />
                    ) : (
                      additional.oc
                    )}
                    {isApproved && (
                      <IconButton onClick={toggleEditOC}>
                        <EditIcon />
                      </IconButton>
                    )}
                  </Box>
                </TableCell>
                {showApproved && (
                  <TableCell>
                    <FormControl fullWidth size="small">
                      <InputLabel id="approvedStatus">Adjudicado</InputLabel>
                      <Select
                        labelId="approvedStatus"
                        id="approvedStatus"
                        label="Adjudicado"
                        value={additional.approvedStatus}
                        onChange={e => handleAdditionalFormChange('approvedStatus', e.target.value)}
                      >
                        <MenuItem value={'Si Adjudicado'}>Si Adjudicado</MenuItem>
                        {!isApproved && (<MenuItem value={'No Adjudicado'}>No Adjudicado</MenuItem>)}
                        {!isApproved && (<MenuItem value={'Sin Respuesta'}>Sin Respuesta</MenuItem>)}
                        <MenuItem value={'Terminado'}>Terminado</MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                )}
                {!isApproved && (
                  <TableCell>
                    {removeAdditionalForm && (
                      <IconButton onClick={removeAdditionalForm}>
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </TableCell>
                )}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {isApproved && (
        <>
          <h4 style={{ textAlign: 'center', marginTop: '20px' }}>{`Etapas de Pago - Total porcentaje: ${totalPercentage}%`}</h4>
          <Box display="flex" justifyContent="center" mt={1}>
            <TableContainer component={Paper} sx={{ maxWidth: '90%' }}>
              <Table aria-label="Tabla de Formas de Pago">
                <TableHead>
                  <TableRow>
                    <TableCell>Clave</TableCell>
                    <TableCell>Numero Recepción</TableCell>
                    <TableCell>Porcentaje</TableCell>
                    <TableCell>Corrección Monetaria</TableCell>
                    {additional.currency === 'UF' && <TableCell>Neto UF</TableCell>}
                    <TableCell>Neto</TableCell>
                    <TableCell>IVA</TableCell>
                    <TableCell>Total</TableCell>
                    <TableCell>Fecha</TableCell>
                    <TableCell>Acciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {additional.paymentForm.map((form, index) => (
                    <TableRow key={index}>
                      <TableCell>{form.key}</TableCell>
                      <TableCell>
                        {editReceptionNumberIndex === index ? (
                          <TextField
                            value={editReceptionNumberValue}
                            onChange={(e) => setEditReceptionNumberValue(e.target.value || '')}
                            size="small"
                            onBlur={() => saveReceptionNumber(index)}
                            fullWidth
                            disabled={!!form.invoice}
                          />
                        ) : (
                          <>
                            {form.receptionNumber}
                            <IconButton disabled={!!form.invoice} onClick={() => editReceptionNumber(index)}>
                              <EditIcon color={!!form.invoice ? "disabled" : "inherit"} />
                            </IconButton>
                          </>
                        )}
                      </TableCell>
                      <TableCell>
                        {editPercentageIndex === index ? (
                          <TextField
                            type="number"
                            value={editPercentageValue || ''}
                            onChange={(e) => setEditPercentageValue(parseFloat(e.target.value) || parseFloat(''))}
                            size="small"
                            onBlur={() => savePercentage(index)}
                            fullWidth
                            disabled={!!form.invoice}
                          />
                        ) : (
                          <>
                            {form.percentage}
                            <IconButton disabled={!!form.invoice} onClick={() => editPercentage(index)}>
                              <EditIcon color={!!form.invoice ? "disabled" : "inherit"} />
                            </IconButton>
                          </>
                        )}
                      </TableCell>
                      <TableCell>
                        {additional.currency === "UF" ? (
                          editUfAdjustmentIndex === index ? (
                            <TextField
                              type="number"
                              value={editUfAdjustmentValue || ''}
                              onChange={(e) => setEditUfAdjustmentValue(parseFloat(e.target.value) || parseFloat(''))}
                              size="small"
                              onBlur={() => saveUfAdjustment(index)}
                              fullWidth
                              disabled={!!form.invoice}
                            />
                          ) : (
                            <>
                              {form.ufAdjustment !== 0 ? form.ufAdjustment.toLocaleString("es-CL", {
                                maximumFractionDigits: 3,
                              }) : 'N/A'}
                              <IconButton disabled={!!form.invoice} onClick={() => editUfAdjustment(index)}>
                                <EditIcon color={!!form.invoice ? "disabled" : "inherit"} />
                              </IconButton>
                            </>
                          )
                        ) : (
                          <>
                            {form.ufAdjustment !== 0 ? form.ufAdjustment.toLocaleString("es-CL", {
                              maximumFractionDigits: 3,
                            }) : 'N/A'}
                            <IconButton disabled>
                              <EditIcon color="disabled" />
                            </IconButton>
                          </>
                        )}
                      </TableCell>
                      {additional.currency === 'UF' && (
                        <TableCell>
                          {editNetUfIndex === index ? (
                            <TextField
                              type="number"
                              value={editNetUfValue || ''}
                              onChange={(e) => setEditNetUfValue(parseFloat(e.target.value) || parseFloat(''))}
                              size="small"
                              onBlur={() => saveNetUf(index)}
                              fullWidth
                              disabled={!!form.invoice}
                            />
                          ) : (
                            <>
                              {form.netUf?.toLocaleString("es-CL", {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              })}
                              <IconButton disabled={!!form.invoice} onClick={() => editNetUf(index)}>
                                <EditIcon color={!!form.invoice ? "disabled" : "inherit"} />
                              </IconButton>
                            </>
                          )}
                        </TableCell>
                      )}
                      <TableCell>
                        {editNetIndex === index ? (
                          <TextField
                            type="number"
                            value={editNetValue || ''}
                            onChange={(e) => setEditNetValue(parseFloat(e.target.value) || parseFloat(''))}
                            size="small"
                            onBlur={() => saveNet(index)}
                            fullWidth
                            disabled={additional.currency === 'UF' || !!form.invoice}
                          />
                        ) : (
                          <>
                            {`$${form.net.toLocaleString("es-CL", {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            })}`}
                            <IconButton disabled={additional.currency === 'UF' || !!form.invoice} onClick={() => editNet(index)}>
                              <EditIcon color={additional.currency === 'UF' || !!form.invoice ? "disabled" : "inherit"} />
                            </IconButton>
                          </>
                        )}
                      </TableCell>
                      <TableCell>{`$${form.iva.toLocaleString("es-CL", {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}`}</TableCell>
                      <TableCell>{`$${form.total.toLocaleString("es-CL", {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}`}</TableCell>
                      <TableCell>
                        {editDateIndex === index ? (
                          <>
                            <DesktopDatePicker
                              format='dd/MM/yyyy'
                              value={editDateValue}
                              onChange={date => setEditDateValue(date)}
                              disabled={!!form.invoice}
                            />
                            <IconButton onClick={() => saveDate(index)} disabled={!!form.invoice}>
                              <SaveIcon color={!!form.invoice ? "disabled" : "inherit"} />
                            </IconButton>
                          </>
                        ) : (
                          <>
                            {formatDate(new Date(form.date))}
                            <IconButton disabled={!!form.invoice} onClick={() => editDate(index)}>
                              <EditIcon color={!!form.invoice ? "disabled" : "inherit"} />
                            </IconButton>
                          </>
                        )}
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={() => removePaymentForm(index)} disabled={!!form.invoice}>
                          <DeleteIcon color={!!form.invoice ? "disabled" : "inherit"} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell>
                      <TextField
                        value={newPaymentForm.key}
                        onChange={e => handlePaymentFormChange('key', e.target.value)}
                        size="small"
                        fullWidth
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        value={newPaymentForm.receptionNumber}
                        onChange={e => handlePaymentFormChange('receptionNumber', e.target.value)}
                        size="small"
                        fullWidth
                      />
                    </TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center" sx={{ maxWidth: '120px' }}>
                        <TextField
                          type="number"
                          value={newPaymentForm.percentage}
                          onChange={(e) => handlePaymentFormChange('percentage', parseFloat(e.target.value))}
                          size="small"
                          fullWidth
                          disabled={toggleEditMode}
                          InputProps={{
                            endAdornment: (
                              <ToggleButton
                                value="check"
                                selected={valueType === 'netUf' || valueType === 'net'}
                                onChange={handleValueTypeToggle}
                                style={{
                                  height: '100%',
                                  borderTopRightRadius: 0,
                                  borderBottomRightRadius: 0,
                                  padding: '4px',
                                  minWidth: '30px',
                                }}
                              >
                                {valueType === 'percentage' ? <PercentOutlinedIcon fontSize="small" /> : <PaidOutlinedIcon fontSize="small" />}
                              </ToggleButton>
                            ),
                          }}
                        />
                      </Box>
                    </TableCell>
                    <TableCell>
                      {additional.currency === "UF" ? (<TextField
                        type="number"
                        value={newPaymentForm.ufAdjustment}
                        onChange={e => handlePaymentFormChange('ufAdjustment', parseFloat(e.target.value))}
                        size="small"
                        fullWidth
                      />) : 'N/A'}
                    </TableCell>
                    {additional.currency === 'UF' && (
                      <TableCell>
                        <TextField
                          type="number"
                          value={newPaymentForm.netUf}
                          onChange={(e) => handlePaymentFormChange('netUf', parseFloat(e.target.value))}
                          size="small"
                          fullWidth
                          disabled={!toggleEditMode}
                        />
                      </TableCell>
                    )}
                    <TableCell>
                      <TextField
                        type="number"
                        value={newPaymentForm.net}
                        onChange={(e) => handlePaymentFormChange('net', parseFloat(e.target.value))}
                        size="small"
                        fullWidth
                        disabled={additional.currency === 'UF' || !toggleEditMode}
                      />
                    </TableCell>
                    <TableCell>{`$${newPaymentForm.iva.toLocaleString("es-CL", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}`}</TableCell>
                    <TableCell>{`$${newPaymentForm.total.toLocaleString("es-CL", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}`}</TableCell>
                    <TableCell>
                      <DesktopDatePicker
                        format='dd/MM/yyyy'
                        value={newPaymentForm.date}
                        onChange={date => {
                          if (!!date) handlePaymentFormChange('date', date)
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Button startIcon={<AddIcon />}
                        disabled={
                          !newPaymentForm.key ||
                          !newPaymentForm.date ||
                          newPaymentForm.percentage <= 0 ||
                          (additional.currency === 'UF' ? (newPaymentForm.netUf ?? 0) <= 0 : newPaymentForm.net <= 0) ||
                          isNaN(newPaymentForm.percentage) ||
                          (additional.currency === 'UF' ? isNaN(newPaymentForm.netUf ?? 0) : isNaN(newPaymentForm.net))
                        } onClick={addNewPaymentForm}>
                        Agregar
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      )}
      {addNewAdditionalForm && title === "Agregar Nuevo Adicional" && (
        <Box mt={4}>
          <h3 className='mb-3'>Nuevo Adicional</h3>
          <TableContainer component={Paper}>
            <Table aria-label="Tabla de Nuevo Adicional">
              <TableBody>
                <TableRow>
                  <TableCell>
                    <TextField
                      label="Nombre Adicional"
                      value={additional.additionalName}
                      onChange={e => handleAdditionalFormChange('additionalName', e.target.value)}
                      size="small"
                      fullWidth
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      label="Precio"
                      type="number"
                      value={additional.price}
                      onChange={e => handleAdditionalFormChange('price', parseFloat(e.target.value))}
                      size="small"
                      fullWidth
                    />
                  </TableCell>
                  <TableCell>
                    <FormControl fullWidth size="small">
                      <InputLabel id="currency">Moneda</InputLabel>
                      <Select
                        labelId="currency"
                        id="currency"
                        value={additional.currency}
                        onChange={e => handleAdditionalFormChange('currency', e.target.value)}
                      >
                        <MenuItem value={'Pesos'}>Pesos</MenuItem>
                        <MenuItem value={'UF'}>UF</MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <DesktopDatePicker
                      format='dd/MM/yyyy'
                      value={additional.date ? new Date(additional.date) : new Date()}
                      onChange={date => handleAdditionalFormChange('date', date as Date)}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      label="Orden de Compra"
                      value={additional.oc}
                      onChange={e => handleAdditionalFormChange('oc', e.target.value)}
                      size="small"
                      fullWidth
                    />
                  </TableCell>
                  <TableCell>
                    <Button startIcon={<AddIcon />}
                      disabled={
                        !additional.additionalName ||
                        !additional.price ||
                        !additional.date ||
                        !additional.currency
                      } onClick={addNewAdditionalForm}>
                      Agregar
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </LocalizationProvider>
  );
};

export default AdditionalForms;

import React, { useState } from 'react';
import {
    Accordion, AccordionDetails, AccordionSummary, Typography, Button, Box,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    Chip, Card, CardContent
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import { Routes } from '../../../config/Routes';
import { useNavigate } from 'react-router-dom';
import { downloadFile, uploadFileToProject } from '../../../services/proyectService';

interface ProjectAccordionProps {
    project: Project;
    handleProjectChange: (project: Project) => void;
}

const ProjectAccordion: React.FC<ProjectAccordionProps> = ({ project, handleProjectChange }) => {
    const navigate = useNavigate();
    const [selectedFile, setSelectedFile] = useState<any>(null);
    const [isUploadingFile, setIsUploadingFile] = useState(false);

    const formattedStatus = (invoice: Invoice | undefined) => {
        if (!invoice) return 'Sin Factura';
        if (invoice.payed) return 'Pagada';
        if (invoice.charged) return 'Cobrada';
        return 'Pendiente Cobro';
    };

    const fileSelectedHandler = (event: any) => {
        setSelectedFile(event.target.files[0]);
        event.target.value = "";
    };

    const handleSendFile = async () => {
        setIsUploadingFile(true);
        const response = await uploadFileToProject(project._id, selectedFile);
        if (response.success) {
            handleProjectChange(response.data);
            setSelectedFile(null);
            setIsUploadingFile(false);
        } else {
            setIsUploadingFile(false);
            alert('Error al subir archivo');
        }
    };

    const handleDownloadFile = () => {
        downloadFile(project.filePath!);
    };

    const formatDate = (date: Date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    return (
        <Accordion
            style={{
                backgroundColor:
                    project.approvedStatus === "Si Adjudicado"
                        ? '#c8e6c9'
                        : project.approvedStatus === "Terminado"
                            ? '#b3e5fc' // Color celeste
                            : '#ffcdd2'
            }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
                <Box style={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="body1" component="div">
                        {project.projectNumber} - {project.projectName}
                    </Typography>
                    <Button
                        size="small"
                        color="inherit"
                        startIcon={<EditIcon style={{ color: 'black' }} />}
                        onClick={(event) => {
                            event.stopPropagation();
                            const editUrl = Routes.EditProject.replace(':id', project._id);
                            navigate(editUrl);
                        }}
                    >
                        Editar
                    </Button>
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                {project.filePath ? (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleDownloadFile}
                    >
                        Descargar Archivo
                    </Button>
                ) : (
                    selectedFile ? (
                        <div className='flex gap-2'>
                            <Chip
                                label={selectedFile.name}
                                color='success'
                                sx={{
                                    marginBottom: '1rem',
                                    maxWidth: '40%',
                                }}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSendFile}
                                disabled={isUploadingFile}
                            >
                                Subir Archivo
                            </Button>
                        </div>
                    ) : (
                        <div>
                            <input
                                accept=".xls, .xlsx"
                                style={{ display: "none" }}
                                id="contained-button-file"
                                type="file"
                                onChange={fileSelectedHandler}
                            />
                            <label htmlFor="contained-button-file">
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size="large"
                                    component="span"
                                >
                                    Cargar Archivo
                                </Button>
                            </label>
                        </div>
                    )
                )}
                <Typography>
                    Tipo: {project.type === 'project' ? 'Proyecto' : project.type === 'construction' ? 'Obra' : project.type}<br />
                    Nombre del Cliente: {project.userName}<br />
                    RUT: {project.rut}<br />
                    Razón Social: {project.legalName}<br />
                    Fecha: {formatDate(new Date(project.date))}<br />
                    Ubicación: {project.location}<br />
                    Comuna: {project.commune}<br />
                    Región: {project.region}<br />
                    Precio: {project.currency === 'Pesos' ?
                        `$${project.price.toLocaleString("es-CL", {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                        })}` : project.price} {project.currency}<br />
                    Orden de Compra: {project.oc}<br />
                    Estado: {project.approvedStatus}<br />
                </Typography>

                {/* Renderización de las formas de pago del proyecto */}
                {project.paymentForm && project.paymentForm.length > 0 && (
                    <TableContainer component={Paper}>
                        <Table aria-label="Tabla de Formas de Pago">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Clave</TableCell>
                                    <TableCell align="right">Porcentaje</TableCell>
                                    <TableCell align="right">Neto</TableCell>
                                    <TableCell align="right">IVA</TableCell>
                                    <TableCell align="right">Total</TableCell>
                                    <TableCell align="right">Fecha</TableCell>
                                    <TableCell align="right">Estado</TableCell>
                                    <TableCell align="right">Numero Factura</TableCell>
                                    <TableCell align="right">Fecha Factura</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {project.paymentForm.map((form, index) => (
                                    <TableRow key={index}>
                                        <TableCell component="th" scope="row">
                                            {form.key}
                                        </TableCell>
                                        <TableCell align="right">{form.percentage}</TableCell>
                                        <TableCell align="right">{`$${form.net.toLocaleString("es", {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0,
                                        })}`}</TableCell>
                                        <TableCell align="right">{`$${form.iva.toLocaleString("es", {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0,
                                        })}`}</TableCell>
                                        <TableCell align="right">{`$${form.total.toLocaleString("es", {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0,
                                        })}`}</TableCell>
                                        <TableCell align="right">{formatDate(new Date(form.date))}</TableCell>
                                        <TableCell align="right">{formattedStatus(form.invoice as unknown as Invoice)}</TableCell>
                                        <TableCell align="right">{form.invoice?.invoiceNumber}</TableCell>
                                        <TableCell align="right">{form.invoice?.date && formatDate(new Date(form.invoice.date))}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}

                {/* Renderización de los adicionales */}
                {project.additionals && project.additionals.length > 0 && (
                    <>
                        <Typography variant="h6" component="div" style={{ marginTop: '1rem' }}>
                            Adicionales
                        </Typography>
                        {project.additionals.map((additional, additionalIndex) => (
                            <Box key={additionalIndex} sx={{ marginBottom: 2 }}>
                                <Card variant="outlined" sx={{ marginBottom: 2 }}>
                                    <CardContent>
                                        <Typography variant="subtitle1" component="div">
                                            AD{(additionalIndex + 1).toString().padStart(2, '0')} - {additional.additionalName}
                                        </Typography>
                                        <Typography>
                                            Nombre del Adicional: {additional.additionalName}<br />
                                            Precio: {additional.currency === 'Pesos' ?
                                                `$${additional.price.toLocaleString("es-CL", {
                                                    minimumFractionDigits: 0,
                                                    maximumFractionDigits: 0,
                                                })}` : additional.price} {additional.currency}<br />
                                            Fecha: {formatDate(new Date(additional.date))}<br />
                                            Orden de Compra: {additional.oc}<br />
                                            Adjudicado: {additional.approvedStatus}<br />
                                        </Typography>
                                    </CardContent>
                                </Card>
                                {additional.paymentForm && additional.paymentForm.length > 0 && (
                                    <TableContainer component={Paper}>
                                        <Table aria-label={`Tabla de Formas de Pago - ${additional.additionalName}`}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Clave</TableCell>
                                                    <TableCell align="right">Porcentaje</TableCell>
                                                    <TableCell align="right">Neto</TableCell>
                                                    <TableCell align="right">IVA</TableCell>
                                                    <TableCell align="right">Total</TableCell>
                                                    <TableCell align="right">Fecha</TableCell>
                                                    <TableCell align="right">Estado</TableCell>
                                                    <TableCell align="right">Numero Factura</TableCell>
                                                    <TableCell align="right">Fecha Factura</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {additional.paymentForm.map((form, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>{form.key}</TableCell>
                                                        <TableCell align="right">{form.percentage}</TableCell>
                                                        <TableCell align="right">{`$${form.net.toLocaleString("es", {
                                                            minimumFractionDigits: 0,
                                                            maximumFractionDigits: 0,
                                                        })}`}</TableCell>
                                                        <TableCell align="right">{`$${form.iva.toLocaleString("es", {
                                                            minimumFractionDigits: 0,
                                                            maximumFractionDigits: 0,
                                                        })}`}</TableCell>
                                                        <TableCell align="right">{`$${form.total.toLocaleString("es", {
                                                            minimumFractionDigits: 0,
                                                            maximumFractionDigits: 0,
                                                        })}`}</TableCell>
                                                        <TableCell align="right">{formatDate(new Date(form.date))}</TableCell>
                                                        <TableCell align="right">{formattedStatus(form.invoice as unknown as Invoice)}</TableCell>
                                                        <TableCell align="right">{form.invoice?.invoiceNumber}</TableCell>
                                                        <TableCell align="right">{form.invoice?.date && formatDate(new Date(form.invoice.date))}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                )}
                            </Box>
                        ))}
                    </>
                )}
            </AccordionDetails>
        </Accordion>
    );
}

export default ProjectAccordion;

interface State {
  token?: string;
  user?: User;
}

const initialState: State = {};

export enum AuthActions {
  SetSession = "SET_SESSION",
  DelSession = "DEL_SESSION",
  SetUser = "SET_USER",
  DelUser = "DEL_USER",
}
// el initial state se pone para empezar los estados, las acciones son lo que modifican los estados
const reducer = (
  state: State = initialState,
  { type, payload }: { type: AuthActions; payload: any }
): State => {
  switch (type) {
    case AuthActions.SetSession:
      return { ...state, token: payload.token };
    case AuthActions.DelSession:
      return { ...state, token: undefined };
    case AuthActions.SetUser:
      return { ...state, user: payload.user };
    case AuthActions.DelUser:
      return { ...state, user: undefined };
    default:
      return state;
  }
};

export default reducer;

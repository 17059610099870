import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

const handleGenerateExcel = async (projects) => {
    // Crear un nuevo libro de trabajo
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Listado de Proyectos');

    // Definir las columnas de acuerdo a la tabla
    worksheet.columns = [
        { header: 'Tipo de Proyecto', key: 'type', width: 20 },
        { header: 'Número de Proyecto', key: 'projectNumber', width: 20 },
        { header: 'Nombre del Proyecto/Adicional', key: 'projectName', width: 30 },
        { header: 'Nombre Legal', key: 'legalName', width: 20 },
        { header: 'RUT', key: 'rut', width: 15 },
        { header: 'Orden de Compra', key: 'oc', width: 20 },
        { header: 'Ultima Factura', key: 'latestInvoiceNumber', width: 20 },
        { header: 'Número de Recepción', key: 'receptionNumber', width: 20 },
        { header: 'Clave de Pago', key: 'paymentKey', width: 20 },
        { header: 'Porcentaje de Pago', key: 'paymentPercentage', width: 15, style: { numFmt: '0%' } },
        { header: 'Neto', key: 'paymentNet', width: 20, style: { numFmt: '#,##0' } },
        { header: 'IVA', key: 'paymentIVA', width: 20, style: { numFmt: '#,##0' } },
        { header: 'Total', key: 'paymentTotal', width: 20, style: { numFmt: '#,##0' } },
        { header: 'Fecha de Facturación', key: 'paymentDate', width: 20 },
    ];

    // Agregar filas con los datos formateados
    projects.forEach((project) => {
        worksheet.addRow({
            type: project.type === 'project' ? 'Proyecto' :
                  project.type === 'additional' ? 'Adicional' :
                  project.type === 'construction' ? 'Obra' : 'N/A',
            projectNumber: project.projectNumber,
            projectName: project.type === 'additional' 
                         ? `${project.projectName} - ${project.additionalName}` 
                         : project.projectName,
            legalName: project.legalName,
            rut: project.rut,
            oc: project.oc || 'N/A',
            latestInvoiceNumber: project.latestInvoiceNumber || 'N/A',
            receptionNumber: project.paymentReceptionNumber || 'N/A',
            paymentKey: project.paymentKey,
            paymentPercentage: project.paymentPercentage / 100,  // Dividimos por 100 para el formato en Excel
            paymentNet: project.paymentNet,
            paymentIVA: project.paymentIVA,
            paymentTotal: project.paymentTotal,
            paymentDate: project.paymentDate ? format(new Date(project.paymentDate), 'dd/MM/yyyy', { locale: es }) : '', // Formateo de la fecha
        });
    });

    // Generar el archivo Excel y guardarlo
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, 'ListadoProyectos.xlsx');
};

export default handleGenerateExcel;

import React, { useState, useEffect } from 'react';
import { Button, TextField, Box, IconButton, Switch, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getProjectById, updateProject } from '../../../services/proyectService';
import { Routes } from '../../../config/Routes';
import { useNavigate, useParams } from 'react-router-dom';
import PaymentForms from './PaymentForms';
import AdditionalForms from './AdditionalForms';

const EditProjectView: React.FC = () => {
  const { id: projectId } = useParams<{ id: string }>();
  const [project, setProject] = useState<Project | null>(null);
  const [paymentForms, setPaymentForms] = useState<PaymentForm[]>([]);
  const [newPaymentForm, setNewPaymentForm] = useState<PaymentForm>({
    key: '',
    receptionNumber: '',
    percentage: 0,
    ufAdjustment: 0,
    net: 0,
    netUf: 0,
    iva: 0,
    total: 0,
    date: new Date(),
  });
  const [additionals, setAdditionals] = useState<Additional[]>([]);
  const [newAdditionalForm, setNewAdditionalForm] = useState<Additional>({
    additionalName: '',
    price: 0,
    currency: '',
    date: new Date(),
    approvedStatus: "No Adjudicado",
    oc: '',
    paymentForm: [],
  });

  const [editReceptionNumberIndex, setEditReceptionNumberIndex] = useState<number | null>(null);
  const [editReceptionNumberValue, setEditReceptionNumberValue] = useState<string>('');

  const [editDateIndex, setEditDateIndex] = useState<number | null>(null);
  const [editDateValue, setEditDateValue] = useState<Date | null>(null);

  const [editPercentageIndex, setEditPercentageIndex] = useState<number | null>(null);
  const [editPercentageValue, setEditPercentageValue] = useState<number | null>(0);

  const [editUfAdjustmentIndex, setEditUfAdjustmentIndex] = useState<number | null>(null);
  const [editUfAdjustmentValue, setEditUfAdjustmentValue] = useState<number | null>(0);

  const [editNetUfIndex, setEditNetUfIndex] = useState<number | null>(null);
  const [editNetUfValue, setEditNetUfValue] = useState<number | null>(0);

  const [editNetIndex, setEditNetIndex] = useState<number | null>(null);
  const [editNetValue, setEditNetValue] = useState<number | null>(0);

  const [toggleEditMode, setToggleEditMode] = useState<boolean>(false);
  const [valueType, setValueType] = useState<'percentage' | 'netUf'>('percentage');

  const navigate = useNavigate();

  const isApproved = project?.approvedStatus === "Si Adjudicado" || project?.approvedStatus === "Terminado";

  useEffect(() => {
    if (!projectId) return navigate(Routes.Projects);
    getProjectById(projectId).then((data) => {
      if (!data) return navigate(Routes.Projects);
      setProject(data as Project);
      setPaymentForms(data.paymentForm || []);
      setAdditionals(data.additionals || []);
    });
  }, [projectId, navigate]);

  const handleValueTypeToggle = () => {
    setValueType(prev => prev === 'percentage' ? 'netUf' : 'percentage');
    setToggleEditMode(!toggleEditMode);
  };

  const handlePaymentFormChange = (field: string | number, value: string | number | Date) => {
    const update = { ...newPaymentForm, [field]: value || '' };
    // Recalcular valores cuando se edita el porcentaje
    if (field === 'percentage' && !toggleEditMode) {
      let baseAmount = project?.price || 0;
      if (project?.currency === 'UF') {
        baseAmount = baseAmount * newPaymentForm.ufAdjustment;
      }
      const net = baseAmount * (value as number / 100);
      const iva = net * 0.19;
      const total = net + iva;
      update.net = parseFloat(net.toFixed(2));
      update.iva = parseFloat(iva.toFixed(2));
      update.total = parseFloat(total.toFixed(2));
      if (project?.currency === 'UF') {
        const netUf = net / newPaymentForm.ufAdjustment;
        update.netUf = parseFloat(netUf.toFixed(2));
      }
    }
    // Recalcular valores cuando se edita la corrección monetaria
    if (field === 'ufAdjustment' && !toggleEditMode) {
      let baseAmount = project?.price || 0;
      baseAmount = baseAmount * (value as number);
      const net = baseAmount * (newPaymentForm.percentage / 100);
      const iva = net * 0.19;
      const total = net + iva;
      update.net = parseFloat(net.toFixed(2));
      update.iva = parseFloat(iva.toFixed(2));
      update.total = parseFloat(total.toFixed(2));
      if (project?.currency === 'UF') {
        const netUf = net / (value as number);
        update.netUf = parseFloat(netUf.toFixed(2));
      }
    }
    // Recalcular valores cuando se edita el neto
    if (field === 'netUf' && toggleEditMode) {
      let baseAmount = project?.price || 0;
      const net = (value as number) * newPaymentForm.ufAdjustment;
      const iva = net * 0.19;
      const total = net + iva;
      const percentage = ((net / newPaymentForm.ufAdjustment) / baseAmount) * 100;
      update.net = parseFloat(net.toFixed(2));
      update.iva = parseFloat(iva.toFixed(2));
      update.total = parseFloat(total.toFixed(2));
      update.percentage = parseFloat(percentage.toFixed(2));
    }
    // Recalcular valores cuando se edita la corrección monetaria en modo de edición
    if (field === 'ufAdjustment' && toggleEditMode) {
      let baseAmount = project?.price || 0;
      baseAmount = baseAmount * (value as number);
      const percentage = (newPaymentForm.net / baseAmount) * 100;
      update.percentage = parseFloat(percentage.toFixed(2));
      if (project?.currency === 'UF') {
        const netUf = newPaymentForm.net / (value as number);
        update.netUf = parseFloat(netUf.toFixed(2));
      }
    }
    // Recalcular valores cuando se edita el neto
    if (field === 'net' && toggleEditMode) {
      const iva = (value as number) * 0.19;
      const total = (value as number) + iva;
      let baseAmount = project?.price || 0;
      const percentage = ((value as number) / baseAmount) * 100;
      update.iva = parseFloat(iva.toFixed(2));
      update.total = parseFloat(total.toFixed(2));
      update.percentage = parseFloat(percentage.toFixed(2));
      if (project?.currency === 'UF') {
        const netUf = (value as number) / newPaymentForm.ufAdjustment;
        update.netUf = parseFloat(netUf.toFixed(2));
      }
    }
    setNewPaymentForm(update);
  };

  const handleAdditionalFormChange = (index: number, field: string | number, value: string | number | Date | boolean) => {
    if (index === -1) {
      setNewAdditionalForm({ ...newAdditionalForm, [field]: value });
    } else {
      const updatedAdditionals = additionals.map((additional, i) =>
        i === index ? { ...additional, [field]: value } : additional
      );
      setAdditionals(updatedAdditionals);
    }
  };

  const addNewPaymentForm = () => {
    if (newPaymentForm.net <= 0 || newPaymentForm.iva <= 0 || newPaymentForm.total <= 0) {
      alert('Debe ser un valor mayor a 0.');
      return;
    }
    if (paymentForms.reduce((sum, form) => sum + form.percentage, 0) + newPaymentForm.percentage > 100) {
      alert('El total de porcentajes no puede execer el 100%.');
      return;
    }
    setPaymentForms([...paymentForms, newPaymentForm]);
    setNewPaymentForm({
      key: '',
      receptionNumber: '',
      percentage: 0,
      ufAdjustment: 0,
      net: 0,
      netUf: 0,
      iva: 0,
      total: 0,
      date: new Date(),
    });
  };

  const addNewAdditionalForm = () => {
    setAdditionals([...additionals, { ...newAdditionalForm, approved: false }]);
    setNewAdditionalForm({
      additionalName: '',
      price: 0,
      currency: '',
      date: new Date(),
      approvedStatus: "No Adjudicado",
      oc: '',
      paymentForm: [],
    });
  };

  const removePaymentForm = (index: number) => {
    setPaymentForms(prevForms => prevForms.filter((_, i) => i !== index));
  };

  const removeAdditionalForm = (index: number) => {
    setAdditionals(prevForms => prevForms.filter((_, i) => i !== index));
  };

  const verifyPercentages = () => {
    const totalProjectPercentage = paymentForms.reduce((sum, form) => sum + form.percentage, 0);
    if (totalProjectPercentage > 100) {
      alert('El total de porcentajes del proyecto no puede ser mayor a 100%');
      return false;
    }

    for (let i = 0; i < additionals.length; i++) {
      const additional = additionals[i];
      const totalAdditionalPercentage = additional.paymentForm.reduce((sum, form) => sum + form.percentage, 0);
      if (totalAdditionalPercentage > 100) {
        alert(`El total de porcentajes en las etapas de pago del AD-${(i + 1).toString().padStart(2, '0')} "${additional.additionalName}" no puede ser mayor a 100%`);
        return false;
      }
    }
    return true;
  };

  const saveProject = async () => {
    if (project) {
      if (verifyPercentages()) {
        const { _id, ...projectData } = project;
        await updateProject(_id, { ...projectData, paymentForm: paymentForms, additionals: additionals }).then((res) => {
          if (res) {
            alert('Proyecto guardado correctamente.');
            navigate(Routes.Projects);
          } else {
            alert('Error al guardar el proyecto.');
          }
        });
      }
    }
  };

  const editReceptionNumber = (index: number) => {
    setEditReceptionNumberIndex(index);
    setEditReceptionNumberValue(paymentForms[index].receptionNumber);
  };

  const saveReceptionNumber = (index: number) => {
    const updatedForms = paymentForms.map((form, i) =>
      i === index ? { ...form, receptionNumber: editReceptionNumberValue } : form
    );
    setPaymentForms(updatedForms);
    setEditReceptionNumberIndex(null);
    setEditReceptionNumberValue('');
  };

  const editDate = (index: number) => {
    setEditDateValue(new Date(paymentForms[index].date));
    setEditDateIndex(index);
  };

  const saveDate = (index: number) => {
    const updatedForms = paymentForms.map((form, i) =>
      i === index ? { ...form, date: new Date(editDateValue!) } : form
    );
    setPaymentForms(updatedForms);
    setEditDateIndex(null);
    setEditDateValue(null);
  };

  const editPercentage = (index: number) => {
    setEditPercentageValue(paymentForms[index].percentage);
    setEditPercentageIndex(index);
  };

  const savePercentage = (index: number) => {
    const updatedForms = paymentForms.map((form, i) => {
      if (i === index) {
        let baseAmount = project?.price || 0;
        if (project?.currency === 'UF') {
          baseAmount = baseAmount * Number(form.ufAdjustment);
        }
        const net = baseAmount * (editPercentageValue! / 100);
        const iva = net * 0.19;
        const total = net + iva;
        const netUf = project?.currency === 'UF' ? net / form.ufAdjustment : net;
        return {
          ...form,
          percentage: editPercentageValue!,
          net: parseFloat(net.toFixed(2)),
          iva: parseFloat(iva.toFixed(2)),
          total: parseFloat(total.toFixed(2)),
          netUf: parseFloat(netUf.toFixed(2))
        };
      }
      return form;
    });

    const totalUpdatedPercentage = updatedForms.reduce((sum, form) => sum + form.percentage, 0);
    if (totalUpdatedPercentage > 100) {
      alert('No se puede exceder el 100%.');
      return;
    }

    const invalidValue = updatedForms.some(form => isNaN(form.net) || isNaN(form.iva) || isNaN(form.total) || form.net <= 0 || form.iva <= 0 || form.total <= 0);
    if (invalidValue) {
      alert('El campo debe ser mayor a 0.');
      return;
    }

    setPaymentForms(updatedForms);
    setEditPercentageIndex(null);
    setEditPercentageValue(null);
  };

  const editUfAdjustment = (index: number) => {
    setEditUfAdjustmentValue(Number(paymentForms[index].ufAdjustment));
    setEditUfAdjustmentIndex(index);
  };

  const saveUfAdjustment = (index: number) => {
    const updatedForms = paymentForms.map((form, i) => {
      if (i === index) {
        let baseAmount = project?.price || 0;
        baseAmount = baseAmount * Number(editUfAdjustmentValue);
        const net = baseAmount * (form.percentage / 100);
        const iva = net * 0.19;
        const total = net + iva;
        const netUf = project?.currency === 'UF' ? net / editUfAdjustmentValue! : net;
        return {
          ...form,
          ufAdjustment: editUfAdjustmentValue!,
          net: parseFloat(net.toFixed(2)),
          iva: parseFloat(iva.toFixed(2)),
          total: parseFloat(total.toFixed(2)),
          netUf: parseFloat(netUf.toFixed(2))
        };
      }
      return form;
    });

    const invalidValue = updatedForms.some(form => isNaN(form.net) || isNaN(form.iva) || isNaN(form.total) || form.net <= 0 || form.iva <= 0 || form.total <= 0);
    if (invalidValue) {
      alert('El campo debe ser mayor a 0.');
      return;
    }

    setPaymentForms(updatedForms);
    setEditUfAdjustmentIndex(null);
    setEditUfAdjustmentValue(null);
  };

  const editNetUf = (index: number) => {
    setEditNetUfValue(paymentForms[index].netUf ?? 0);
    setEditNetUfIndex(index);
  };

  const saveNetUf = (index: number) => {
    const updatedForms = paymentForms.map((form, i) => {
      if (i === index) {
        const net = editNetUfValue! * form.ufAdjustment;
        const iva = net * 0.19;
        const total = net + iva;
        const percentage = ((net / form.ufAdjustment) / project?.price!) * 100;
        return {
          ...form,
          netUf: editNetUfValue!,
          net: parseFloat(net.toFixed(2)),
          iva: parseFloat(iva.toFixed(2)),
          total: parseFloat(total.toFixed(2)),
          percentage: parseFloat(percentage.toFixed(2))
        };
      }
      return form;
    });

    const invalidValue = updatedForms.some(form => isNaN(form.net) || isNaN(form.iva) || isNaN(form.total) || form.net <= 0 || form.iva <= 0 || form.total <= 0);
    if (invalidValue) {
      alert('El campo debe ser mayor a 0.');
      return;
    }

    setPaymentForms(updatedForms);
    setEditNetUfIndex(null);
    setEditNetUfValue(null);
  };

  const editNet = (index: number) => {
    setEditNetValue(paymentForms[index].net);
    setEditNetIndex(index);
  };

  const saveNet = (index: number) => {
    const updatedForms = paymentForms.map((form, i) => {
      if (i === index) {
        const iva = (editNetValue as number) * 0.19;
        const total = (editNetValue as number) + iva;
        let baseAmount = project?.price || 0;
        if (project?.currency === 'UF') {
          baseAmount = baseAmount * Number(form.ufAdjustment);
        }
        const percentage = ((editNetValue as number) / baseAmount) * 100;
        const netUf = project?.currency === 'UF' ? (editNetValue as number) / form.ufAdjustment : undefined;
        return {
          ...form,
          net: editNetValue!,
          iva: parseFloat(iva.toFixed(2)),
          total: parseFloat(total.toFixed(2)),
          percentage: parseFloat(percentage.toFixed(2)),
          netUf: project?.currency === 'UF' ? parseFloat(netUf!.toFixed(2)) : undefined
        };
      }
      return form;
    });

    const invalidValue = updatedForms.some(form => isNaN(form.net) || isNaN(form.iva) || isNaN(form.total) || form.net <= 0 || form.iva <= 0 || form.total <= 0);
    if (invalidValue) {
      alert('El campo debe ser mayor a 0.');
      return;
    }

    setPaymentForms(updatedForms);
    setEditNetIndex(null);
    setEditNetValue(null);
  };

  if (!project) {
    return <div>Cargando...</div>;
  }

  return (
    <Box padding={2}>
      <IconButton className="mb-5" onClick={() => navigate(-1)}>
        <ArrowBackIcon />
      </IconButton>
      <h2 className='mb-3'>Editar Proyecto {project?.projectName}</h2>
      <h3 className='mb-3'>Información del Proyecto</h3>
      <div className='flex flex-col gap-2 mb-3'>
        <FormControl fullWidth size="small">
          <InputLabel id="approvedStatus">Adjudicado</InputLabel>
          <Select
            labelId="approvedStatus"
            id="approvedStatus"
            label="Adjudicado"
            value={project?.approvedStatus}
            onChange={(e) => {
              setProject({ ...project!, approvedStatus: e.target.value as string })
            }}
          >
            <MenuItem value={'Si Adjudicado'}>Si Adjudicado</MenuItem>
            <MenuItem value={'No Adjudicado'}>No Adjudicado</MenuItem>
            <MenuItem value={'Sin Respuesta'}>Sin Respuesta</MenuItem>
            <MenuItem value={'Terminado'}>Terminado</MenuItem>
          </Select>
        </FormControl>
        {isApproved ? <div>Nombre Proyecto: {project.projectName} </div> : (<TextField
          label="Nombre del Proyecto"
          value={project?.projectName}
          onChange={e => {
            setProject({ ...project!, projectName: e.target.value })
          }}
          fullWidth
          InputLabelProps={{ shrink: true }}
          size="small"
        />)}
        <div>Numero de Proyecto: {project.projectNumber}</div>
        {isApproved ? <div>Nombre Cliente: {project.userName} </div> : (<TextField
          label="Nombre Cliente"
          value={project?.userName}
          onChange={e => {
            setProject({ ...project!, userName: e.target.value })
          }}
          fullWidth
          InputLabelProps={{ shrink: true }}
          size="small"
        />)}
        <TextField
          label="Rut"
          value={project?.rut}
          onChange={e => {
            setProject({ ...project!, rut: e.target.value })
          }}
          fullWidth
          InputLabelProps={{ shrink: !!project?.rut }}
          size="small"
        />
        <TextField
          label="Razón Social"
          value={project?.legalName}
          onChange={e => {
            setProject({ ...project!, legalName: e.target.value })
          }}
          fullWidth
          InputLabelProps={{ shrink: !!project?.legalName }}
          size="small"
        />
        {isApproved ? <div> Ubicacion: {project.location} </div> : (<TextField
          label="Ubicación"
          value={project?.location}
          onChange={e => {
            setProject({ ...project!, location: e.target.value })
          }}
          fullWidth
          InputLabelProps={{ shrink: true }}
          size="small"
        />)}
        {isApproved ? <div>Comuna: {project.commune} </div> : (<TextField
          label="Comuna"
          value={project?.commune}
          onChange={e => {
            setProject({ ...project!, commune: e.target.value })
          }}
          fullWidth
          InputLabelProps={{ shrink: true }}
          size="small"
        />)}
        {isApproved ? <div>Region: {project.region} </div> : (<TextField
          label="Región"
          value={project?.region}
          onChange={e => {
            setProject({ ...project!, region: e.target.value })
          }}
          fullWidth
          InputLabelProps={{ shrink: true }}
          size="small"
        />)}
        {isApproved ? (
          <div>
            Precio: {project.currency === 'Pesos' ?
              `$${project.price?.toLocaleString("es-CL", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}` : project.price}
          </div>
        ) : (
          <TextField
            label="Precio"
            value={project?.price}
            onChange={e => {
              setProject({ ...project!, price: parseFloat(e.target.value) })
            }}
            fullWidth
            InputLabelProps={{ shrink: true }}
            type='number'
            size="small"
          />
        )}
        {isApproved ? <div>Moneda: {project.currency}</div> : (<FormControl fullWidth size="small">
          <InputLabel id="currency">Moneda</InputLabel>
          <Select
            labelId="currency"
            id="currency"
            label="Moneda"
            value={project?.currency}
            onChange={(e) => {
              setProject({ ...project!, currency: e.target.value as string })
            }}
          >
            <MenuItem value={'Pesos'}>Pesos</MenuItem>
            <MenuItem value={'UF'}>UF</MenuItem>
          </Select>
        </FormControl>)}
        <TextField
          label="Orden de Compra"
          value={project?.oc || ''}
          onChange={e => {
            setProject({ ...project!, oc: e.target.value })
          }}
          fullWidth
          InputLabelProps={{ shrink: !!project?.oc }}
          size="small"
        />
      </div>
      {isApproved && (
        <>
          <PaymentForms
            paymentForms={paymentForms}
            editReceptionNumberIndex={editReceptionNumberIndex}
            setEditReceptionNumberValue={setEditReceptionNumberValue}
            editReceptionNumberValue={editReceptionNumberValue}
            editDateIndex={editDateIndex}
            setEditDateValue={setEditDateValue}
            editDateValue={editDateValue}
            editPercentageIndex={editPercentageIndex}
            setEditPercentageValue={setEditPercentageValue}
            editPercentageValue={editPercentageValue}
            editUfAdjustmentIndex={editUfAdjustmentIndex}
            setEditUfAdjustmentValue={setEditUfAdjustmentValue}
            editUfAdjustmentValue={editUfAdjustmentValue}
            editNetUfIndex={editNetUfIndex}
            setEditNetUfValue={setEditNetUfValue}
            editNetUfValue={editNetUfValue}
            editNetIndex={editNetIndex}
            setEditNetValue={setEditNetValue}
            editNetValue={editNetValue}
            handlePaymentFormChange={handlePaymentFormChange}
            addNewPaymentForm={addNewPaymentForm}
            removePaymentForm={removePaymentForm}
            editReceptionNumber={editReceptionNumber}
            saveReceptionNumber={saveReceptionNumber}
            editDate={editDate}
            saveDate={saveDate}
            editPercentage={editPercentage}
            savePercentage={savePercentage}
            editUfAdjustment={editUfAdjustment}
            saveUfAdjustment={saveUfAdjustment}
            editNetUf={editNetUf}
            saveNetUf={saveNetUf}
            editNet={editNet}
            saveNet={saveNet}
            newPaymentForm={newPaymentForm}
            project={project}
            handleValueTypeToggle={handleValueTypeToggle}
            toggleEditMode={toggleEditMode}
            valueType={valueType}
          />
          <Box mt={4}>
            {additionals.map((additional, index) => (
              <Box key={index} mb={2}>
                <AdditionalForms
                  additional={additional}
                  handleAdditionalFormChange={(field, value) => handleAdditionalFormChange(index, field, value)}
                  removeAdditionalForm={() => removeAdditionalForm(index)}
                  title={`Adicional - AD${(index + 1).toString().padStart(2, '0')}`}
                  showApproved
                />
              </Box>
            ))}
          </Box>
          <Box mt={4}>
            <AdditionalForms
              additional={newAdditionalForm}
              handleAdditionalFormChange={(field, value) => handleAdditionalFormChange(-1, field, value)}
              addNewAdditionalForm={addNewAdditionalForm}
              title="Agregar Nuevo Adicional"
              showApproved={false}
            />
          </Box>
        </>
      )}
      <Button startIcon={<SaveIcon />}
        onClick={saveProject}
        color="primary"
      >
        Guardar
      </Button>
    </Box>
  );
};

export default EditProjectView;

import {
    DataGridPro,
    GridColDef,
    esES,
    GridToolbar,
} from "@mui/x-data-grid-pro";
import { Button, Card, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { mdiCircleSlice1 } from "@mdi/js";
import Icon from "@mdi/react";
import { getPaymentForms } from "../../../services/proyectService";
import AssignInvoiceModal from "./AssignInvoiceModal";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';
import es from 'date-fns/locale/es';
import handleGenerateExcel from "../../../utils/createPendingInvoicesExcel";

const PendingInvoicesView: React.FC = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [pendingInvoices, setPendingInvoices] = useState<PaymentFormUnwind[]>([]);
    const [filteredInvoices, setFilteredInvoices] = useState<PaymentFormUnwind[]>([]);
    const [selectedRows, setSelectedRows] = useState<String[]>([]);
    const [openAssignModal, setOpenAssignModal] = useState(false);
    const [selectedPaymentForm, setSelectedPaymentForm] = useState<PaymentFormUnwind | null>(null);

    // Estados para los filtros
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [projectNumber, setProjectNumber] = useState<number>(0);

    const columns: GridColDef[] = [
        {
            field: "type",
            headerName: "Tipo de Proyecto",
            minWidth: 150,
            flex: 1,
            valueGetter(params) {
                switch (params.row.type) {
                    case 'project':
                        return 'Proyecto';
                    case 'additional':
                        return 'Adicional';
                    case 'construction':
                        return 'Obra';
                    default:
                        return 'N/A';
                }
            },
        },
        {
            field: "projectNumber",
            headerName: "Número de Proyecto",
            minWidth: 150,
            flex: 1,
        },
        {
            field: "projectName",
            headerName: "Nombre del Proyecto/Adicional",
            minWidth: 225,
            flex: 1,
            valueGetter(params) {
                switch (params.row.type) {
                    case 'project':
                        return params.row.projectName;
                    case 'additional':
                        return params.row.projectName + " - " + params.row.additionalName;
                    default:
                        return params.row.projectName;
                }
            },
        },
        {
            field: "legalName",
            headerName: "Nombre Legal",
            minWidth: 150,
            flex: 1,
        },
        {
            field: "rut",
            headerName: "RUT",
            minWidth: 150,
            flex: 1,
        },
        {
            field: "oc",
            headerName: "Orden de Compra",
            minWidth: 150,
            flex: 1,
            valueGetter(params) {
                return params.row.oc || "N/A";
            }
        },
        {
            field: "latestInvoiceNumber",
            headerName: "Última Factura",
            minWidth: 150,
            flex: 1,
        },
        {
            field: "receptionNumber",
            headerName: "Número de Recepción",
            minWidth: 150,
            flex: 1,
            valueGetter(params) {
                return params.row.paymentReceptionNumber || "N/A";
            },
        },
        {
            field: "paymentKey",
            headerName: "Clave de Pago",
            minWidth: 150,
            flex: 1,
        },
        {
            field: "paymentPercentage",
            headerName: "Porcentaje de Pago",
            minWidth: 150,
            flex: 1,
            type: 'number',
            valueFormatter: ({ value }) => `${value}%`,  // Formatea el porcentaje
        },
        {
            field: "paymentNet",
            headerName: "Neto",
            minWidth: 150,
            flex: 1,
            type: 'number',
            valueFormatter: ({ value }) => `$${value.toLocaleString('es-CL', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            })}`,
        },
        {
            field: "paymentIVA",
            headerName: "IVA",
            minWidth: 150,
            flex: 1,
            type: 'number',
            valueFormatter: ({ value }) => `$${value.toLocaleString('es-CL', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            })}`,
        },
        {
            field: "paymentTotal",
            headerName: "Total",
            minWidth: 150,
            flex: 1,
            type: 'number',
            valueFormatter: ({ value }) => `$${value.toLocaleString('es-CL', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            })}`,
        },
        {
            field: "paymentDate",
            headerName: "Fecha de Facturación",
            minWidth: 150,
            flex: 1,
            type: 'date',
            valueFormatter: ({ value }) => format(new Date(value), 'dd/MM/yyyy', { locale: es }),  // Formatea la fecha
        }
    ];

    useEffect(() => {
        getPaymentForms().then((data) => {
            setPendingInvoices(data);
            setFilteredInvoices(data);  // Se inicializa el estado de los filtros [2/2]
            setIsLoading(false);
        });
    }, []);

    useEffect(() => {
        applyFilters();
    }, [startDate, endDate, projectNumber]);

    const applyFilters = () => {
        let filtered = pendingInvoices;

        if (startDate) {
            filtered = filtered.filter(invoice => new Date(invoice.paymentDate) >= startDate);
        }

        if (endDate) {
            filtered = filtered.filter(invoice => new Date(invoice.paymentDate) <= endDate);
        }

        if (projectNumber !== 0) {
            filtered = filtered.filter(invoice => invoice.projectNumber === projectNumber);
        }

        setFilteredInvoices(filtered);
    };

    return (
        <div className="p-5 h-full overflow-auto">

            <div className="text-xl mb-5">Trabajos pendientes por facturar</div>

            <div>
                {isLoading ? (
                    <div className="flex justify-center w-full col-span-full">
                        <Icon spin path={mdiCircleSlice1} className="w-7"></Icon>
                    </div>
                ) : (
                    <>
                        <div className="flex gap-2 mb-2">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    format='dd/MM/yyyy'
                                    label="Fecha de inicio"
                                    value={startDate}
                                    onChange={(newValue) => {
                                        // set hours and minutes to 0 to avoid problems with the time
                                        if (newValue) {
                                            newValue.setHours(0);
                                            newValue.setMinutes(0);
                                        }
                                        setStartDate(newValue)
                                    }}
                                />
                                <DatePicker
                                    format='dd/MM/yyyy'
                                    label="Fecha de fin"
                                    value={endDate}
                                    onChange={(newValue) => {
                                        // set hours and minutes to 23:59 to avoid problems with the time
                                        if (newValue) {
                                            newValue.setHours(23);
                                            newValue.setMinutes(59);
                                        }
                                        setEndDate(newValue)
                                    }}
                                />
                            </LocalizationProvider>
                            <TextField
                                label="Número de Proyecto"
                                value={projectNumber}
                                onChange={(e) => setProjectNumber(Number(e.target.value))}
                            />
                        </div>
                        <Card>
                            <Button variant="contained"
                                onClick={
                                    async () => {
                                        let objectToExcel: PaymentFormUnwind[] = []
                                        filteredInvoices.forEach((r) => {
                                            if (selectedRows.includes(r.additionalName + r.paymentKey + r.projectNumber)) {
                                                objectToExcel.push(r)
                                            }
                                        })
                                        await handleGenerateExcel(objectToExcel)
                                    }
                                }>
                                Descargar en excel
                            </Button>
                            <DataGridPro
                                sx={{
                                    padding: 2,
                                    '& .row-red': {
                                        backgroundColor: 'rgba(255, 0, 0, 0.2)',  // Estilo para las filas con paymentDate < Date.now()
                                    }
                                }}
                                autoHeight
                                columns={columns}
                                rows={filteredInvoices}
                                getRowId={(row) => {
                                    return row.additionalName + row.paymentKey + row.projectNumber
                                }}
                                getRowClassName={(params) => {
                                    if (!params.row.paymentDate) return '';
                                    const paymentDate = new Date(params.row.paymentDate);
                                    return paymentDate < new Date() ? 'row-red' : '';
                                }}
                                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                components={{
                                    Toolbar: GridToolbar,
                                }}
                                rowsPerPageOptions={[10, 25, 50, 100]}
                                pagination
                                onRowDoubleClick={(params) => {
                                    setSelectedPaymentForm(params.row as PaymentFormUnwind);
                                    setOpenAssignModal(true);
                                }}
                                initialState={{
                                    pagination: {
                                        pageSize: 10,
                                    },
                                }}
                                onStateChange={(state) => {
                                    let selectedRowsToSet: String[] = []
                                    Object.keys(state.filter.visibleRowsLookup).forEach((key) => {
                                        if (state.filter.visibleRowsLookup[key]) {
                                            selectedRowsToSet.push(key)
                                        }
                                    })

                                    // Solo actualiza el estado si realmente cambió, para evitar problemas de re-renderizado
                                    if (JSON.stringify(selectedRowsToSet) !== JSON.stringify(selectedRows)) {
                                        setSelectedRows(selectedRowsToSet);
                                    }
                                }}
                            />
                            <AssignInvoiceModal
                                open={openAssignModal}
                                onClose={() => {
                                    setIsLoading(true);
                                    setOpenAssignModal(false)
                                    getPaymentForms().then((data) => {
                                        setPendingInvoices(data);
                                        setFilteredInvoices(data);
                                        setIsLoading(false);
                                    })
                                }}
                                selectedPaymentForm={selectedPaymentForm}
                            />
                        </Card>
                    </>
                )}
            </div>
        </div>
    );
};
export default PendingInvoicesView;
